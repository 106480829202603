<div class="cover-widget">
  <div class="container">
    <h1 class="text-center">{{ "madfu-faq" | translate }}</h1>
  </div>
</div>
<div class="widget-data">
  <div class="container">
    <h2>{{ "general-info" | translate }}</h2>
    <div class="item" *ngFor="let item of faqList?.commonFAQ">
      <h5>
        {{
          currentLang === ApplicationLanguages.Ar ? item.titleAr : item.titleEn
        }}
      </h5>
      <p [class.ar]="currentLang === ApplicationLanguages.Ar">
        {{
          currentLang === ApplicationLanguages.Ar
            ? item.descriptionAr
            : item.descriptionEn
        }}
      </p>
    </div>

    <h2>{{ "manage-account" | translate }}</h2>
    <div class="item" *ngFor="let item of faqList?.manageAccount">
      <h5>
        {{
          currentLang === ApplicationLanguages.Ar ? item.titleAr : item.titleEn
        }}
      </h5>
      <p [class.ar]="currentLang === ApplicationLanguages.Ar">
        {{
          currentLang === ApplicationLanguages.Ar
            ? item.descriptionAr
            : item.descriptionEn
        }}
      </p>
    </div>

    <h2>{{ "payment-process" | translate }}</h2>
    <div class="item" *ngFor="let item of faqList?.paymentProcess">
      <h5>
        {{
          currentLang === ApplicationLanguages.Ar ? item.titleAr : item.titleEn
        }}
      </h5>
      <p [class.ar]="currentLang === ApplicationLanguages.Ar">
        {{
          currentLang === ApplicationLanguages.Ar
            ? item.descriptionAr
            : item.descriptionEn
        }}
      </p>
    </div>

    <h2>{{ "madfu-partners" | translate }}</h2>
    <div class="item" *ngFor="let item of faqList?.madfuPartners; index as i">
      <h5>
        {{
          currentLang === ApplicationLanguages.Ar ? item.titleAr : item.titleEn
        }}
      </h5>
      <p
        [class.ar]="currentLang === ApplicationLanguages.Ar"
        [innerHTML]="
          currentLang === ApplicationLanguages.Ar
            ? item.descriptionAr
            : item.descriptionEn
        "
      ></p>
    </div>
  </div>
</div>
