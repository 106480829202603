<li [ngClass]="{ reverse: isEven }" class="blog-item">
  <img
    loading="lazy"
    *ngIf="!isEven && blog.img"
    class="blog-img"
    [src]="blog.img"
    [alt]="currentLang == ApplicationLanguages.Ar ? blog.titleAr : blog.titleEn"
  />
  <video *ngIf="!isEven && blog.video" class="blog-img" controls>
    <source [src]="blog.video" />
  </video>

  <div class="blogs-data">
    <h2>
      {{ currentLang == ApplicationLanguages.Ar ? blog.titleAr : blog.titleEn }}
    </h2>
    <div class="html-content" [innerHTML]="blogViewedContent"></div>
    <div class="date-and-actions">
      <p>
        <img loading="lazy" src="/assets/images/heart.svg" alt="favorite" />
        <span>{{
          currentLang == ApplicationLanguages.Ar
            ? (blog.date | date : "dd MMM yyyy" : "" : ApplicationLanguages.Ar)
            : (blog.date | date : "dd MMM yyyy")
        }}</span>
      </p>
      <button (click)="changeIsExpanded()">
        {{ isExpanded ? ("show-less" | translate) : ("read-more" | translate) }}
      </button>
    </div>
  </div>
  <img
    loading="lazy"
    *ngIf="isEven && blog.img"
    class="blog-img"
    [src]="blog.img"
    [alt]="currentLang == ApplicationLanguages.Ar ? blog.titleAr : blog.titleEn"
  />
  <video *ngIf="isEven && blog.video" class="blog-img" controls>
    <source [src]="blog.video" />
  </video>
</li>
