import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CategoriesService } from './service/categories.service';
import { Category, partner } from 'src/app/interfaces/category';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationLanguages } from 'src/app/enums/lang';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  deviceInfo: any;
  macOS = 'mac';
  iosOS = 'ios';
  discoverLink: string = '';

  categories: Category[] = [];
  sliderCategories: Category[] = [];
  storePartners: partner[] = [];
  onlinePartners: partner[] = [];
  ApplicationLanguages = ApplicationLanguages;
  currentLang = localStorage.getItem('lang');

  customOptionsSliderOne: any = {
    loop: true,
    margin: 20,
    nav: false,
    autoplay: true,
    autoplayTimeout: 1500,
    rtl: true,
    smartSpeed: 600,
    dots: false,
    autoplayHoverPause: false,
    slideTransition: 'ease-in-out',
    responsive: {
      0: {
        items: 1,
      },
    },
  };

  paymentExpandedId: number = 0;

  constructor(
    private deviceService: DeviceDetectorService,
    private categoriesService: CategoriesService,
    protected translate: TranslateService
  ) {
    this.checkOS();
  }

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById('defaultOpen')!.click();
    }, 500);

    this.getCategoriesData();
    this.getStorePartnersData();
    this.getOnlinePartnersData();
  }

  getStorePartnersData() {
    this.categoriesService.getStorePartners().subscribe((response: any) => {
      this.storePartners = response;
    });
  }
  getOnlinePartnersData() {
    this.categoriesService.getOnlinePartners().subscribe((response: any) => {
      this.onlinePartners = response;
    });
  }

  getCategoriesData() {
    this.categoriesService.getCategories().subscribe(
      (response: any) => {
        this.categories = response;
        this.sliderCategories = [
          {
            title: this.translate.instant('TRY_IT_OUT'),
            image: 'img-2.svg',
          },
          ...response,
        ];
      },
      (error: any) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  togglePaymentExpanded(id: number) {
    this.paymentExpandedId = this.paymentExpandedId === id ? 0 : id;
  }

  openItem(evt: MouseEvent, itemName: string): void {
    // Declare all variables
    let i: number;
    let tabcontent: HTMLCollectionOf<Element>;
    let tablinks: HTMLCollectionOf<Element>;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      (tabcontent[i] as HTMLElement).style.display = 'none';
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(itemName)!.style.display = 'block';
    (evt.currentTarget as HTMLElement).className += ' active';
  }

  checkOS() {
    this.deviceInfo = this.deviceService.getDeviceInfo();

    if (
      this.deviceInfo.os.toLocaleLowerCase() == this.macOS ||
      this.deviceInfo.os.toLocaleLowerCase() == this.iosOS
    ) {
      this.discoverLink = 'https://app.adjust.com/1fz1h1en';
    } else {
      this.discoverLink = 'https://app.adjust.com/1fz1h1en';
    }
  }
}
