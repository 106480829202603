<div class="cover-widget">
  <div class="container">
    <h1>{{ "TERMS_CONDITIONS" | translate }}</h1>
  </div>
</div>

<div class="terms-data" *ngIf="currentLang == ApplicationLanguages.Ar">
  <div class="container">
    <p class="heading center first-item">
      <b>اتفاقية &quot;مدفوع&quot; (الشروط والأحكام)</b>
    </p>
    <strong>
      <p class="heading center">تاريخ السريان 16/04/2024</p>
    </strong>
    <p></p>
    <p>
      <strong><label class="heading">التمهيد:</label></strong>
    </p>
    <p>
      شركة مدفوع العربية للتقنية المالية، شركة مساهمة غير مدرجة، سجل تجاري رقم
      (١٠١٠٧٤٧٠٧٧) المسجل في مدينة الرياض، بالمملكة العربية السعودية، ويشار
      إليها فيما بعد بـ(&quot;نحن أو الشركة أو مدفوع أو المنصة&quot;)، هي شركة
      مصرح لها من البنك المركزي لممارسة نشاط الدفع الآجل (BNPL)
      (&quot;الخدمات&quot;)، وذلك من خلال موقع مدفوع الإلكتروني أو تطبيق مدفوع
      أو المتاجر الإلكترونية أو الفعلية التي تتيح الدفع عن طريق مدفوع
      (&quot;قنوات مدفوع&quot;)؛ وينحصر دور مدفوع في تسهيل عملية دفع قيمة
      العمليات الشرائية التي تتم بين الأفراد والمتاجر، ولا تمتد مسؤوليته إلى أي
      من مسؤوليات الأفراد أو المتاجر المعتادة تجاه بعضهما البعض. منصة مدفوع ليست
      متجر، وهي غير مسؤولة عن دقة المعلومات المعروضة من قبل المتاجر أو نظامية
      المنتجات أو الخدمات المقدمة من قبل المتاجر المشاركين. كما أن منصة مدفوع
      ليست جهة تحقق من معلومات أو هويات الأفراد المستفيدين من الخدمة. وفي جميع
      الأحوال تتحمل المتاجر ويتحمل الأفراد كامل المسؤوليات المتعلقة بعمليات
      الشراء التي تجري على المنصة فيما بينهما
    </p>
    <p>
      وحيث إنك ترغب في الاستفادة من الخدمات المقدمة من مدفوع، فإنك تُقر &ndash;
      بمجرد وصولك أو استخدامك لأي من خدمات مدفوع أو تسجيل حساب مستخدم - بأنك
      اطلعت على هذه الشروط والأحكام وفهمتها ووافقت على الالتزامات المنصوص فيها،
      وتعتبر الشروط والأحكام اتفاق إلزامي تم إبرامه بين الطرفين وفق للآتي:
    </p>
    <p>
      <strong><label class="heading">(١): أهمية التمهيد:</label></strong>
    </p>
    <p>
      يعتبر التمهيد أعلاه جزءً لا يتجزأ من هذه الاتفاقية ومكملاً له وتفسر بنوده
      وفقاً له.
    </p>
    <p>
      <strong><label class="heading">(٢): أطراف الاتفاقية:</label></strong>
    </p>
    <p>
      2/1تعتبر هذه الاتفاقية عقد مبرم بين مدفوع وبين كل من يستفيد أو يصل لخدمات
      مدفوع (&quot;المستفيد&quot;) عبر أي من قنوات مدفوع.
    </p>
    <p>
      2/2لا يعتبر التاجر الذي يشتري منه المستفيد سلع أو خدمات باستخدام خدمات
      مدفوع طرفاً في هذه الاتفاقية، وأي شروط سارية بين التاجر والمستفيد أو
      التاجر ومدفوع تعد مستقلة عن هذه الاتفاقية.
    </p>
    <p>
      <strong><label class="heading">(٣): التعريفات:</label></strong>
    </p>
    <p>
      لأغراض تطبيق هذه الاتفاقية، يقصد بالكلمات والعبارات الآتية -أينما وردت في
      الاتفاقية- المعاني المبينة أمام كل منها، ما لم يقتضِ السياق غير ذلك:
    </p>
    <p>
      3/1 الاتفاقية:يقصد فيهاالشروط والأحكام المنصوص عليها في هذه الاتفاقية بما
      فيها سياسة الخصوصية الخاصة بمدفوع وأي تحديثات تطرأ عليها من وقت إلى آخر،
      وأي شروط وأحكام أخرى يتم الإشارة إلى أنها جزء من هذه الاتفاقية.
    </p>
    <p>
      3/2 الشركة أو مدفوع:يقصد بها شركة مدفوع المحدودة، سجل تجاري رقم
      (١٠١٠٧٤٧٠٧٧) المسجل في مدينة الرياض، بالمملكة العربية السعودية
      (&quot;مدفوع&quot;)، وقد يشار إليها بالضمير (&quot;نحن&quot;) أو نون
      الجماعة أو أي ضمائر أخرى مماثلة في هذه الاتفاقية.
    </p>
    <p>
      3/3 المستفيد:هو الشخص ذو الصفة الطبيعية الذي يستخدم أي من خدمات مدفوع
      لإتمام عملية شراء مع أي من المتاجر المشاركة، وقد يشار إليه بضمير
      &quot;أنت&quot; أو ضمير المخاطب (ك) أو أي ضمائر أخرى مماثلة في هذه
      الاتفاقية.
    </p>
    <p>
      3/4 التاجر أو المتاجر المشاركة:ويقصد به المتجر الإلكتروني أو التطبيقات أو
      المتاجر الفعلية التي توفر خدمات السداد عبر مدفوع، أو المتاجر المعروضة في
      منصة مدفوع، والتي يعرض فيها التاجر سلعة والخدمات والمنتجات الخاصة به
      وغيرها، وفقاً للاتفاقية بينه وبين مدفوع.
    </p>
    <p>
      3/5 قنوات مدفوع: تعني الوسيلة التي يقدمها التاجر للمستفيد لتمكين المستفيد
      من اختيار مدفوع كوسيلة دفع ويشمل ذلك على سبيل المثال لا الحصر الباركود
      الموضح في الفاتورة الذي ينقل المستفيد لصفحة الدفع الخاصة في مدفوع.
    </p>
    <p>
      3/6 الموقع الإلكتروني:يقصد به الموقع الإلكتروني الخاص بشركة
      مدفوعWWW.MADFU.COM.SA
    </p>
    <p>
      3/7 التطبيق:تعني تطبيق مدفوع المتوفر على (Apple App Store) أو (Google Play
      Store) أو أي نظام تشغيل آخر يتوفر فيه التطبيق الخاص بمنصة مدفوع.
    </p>
    <p>
      3/8 حساب مدفوع:يقصد به الحساب الذي قام العميل بإنشائه لكي يتمكن من استخدام
      خدمات الدفع من مدفوع حسب الشروط والأحكام المنصوص عليها في هذه الاتفاقية.
    </p>
    <p>
      3/9 محفظة مدفوع:هي محفظة مالية إلكترونية مرتبطة بحسابك في مدفوع، ويمكن
      إيداع مبالغ مالية فيها أو السداد منها للمشتريات من المتاجر المشاركة.
    </p>
    <p>
      3/10 خدمات الدفع من مدفوع:تسهيل إجراءات الدفع الآجل للأفراد عبر الدفع
      بالكامل أو التقسيط.
    </p>
    <p>
      <strong><label class="heading">(4): إقرارات المستفيد:</label></strong>
    </p>
    <p>
      4/1 يقر المستفيد بأنه بكامل الأهلية المعتبرة شرعاً ونظاماً مما يمكنه إبرام
      هذه الاتفاقية وفق الشروط والأحكام المنصوص عليها، وأنه أهل للتصرفات
      القانونية اللازمة لفتح حساب أو استخدام خدمات مدفوع.
    </p>
    <p>
      4/2 يقر المستفيد بأنه يعد الوصول إلى منصة مدفوع أو استخدام أي من خدمات
      مدفوع أو النقر على الموافقة على الشروط والأحكام المنصوص في هذه الاتفاقية
      إقراراً منه على إبرام هذه الاتفاقية إلكترونياً بينه وبين مدفوع حسب أنظمة
      المملكة العربية السعودية.
    </p>
    <p>
      4/3 يقر المستفيد بمسؤوليته عن مراجعة المعلومات المنشورة على تطبيقنا أو
      موقعنا الإلكتروني بانتظام ويستعرض هذه الاتفاقية بانتظام قبل استخدامه لأي
      من خدمات مدفوع. واستمرار استخدامه للخدمات على هذه الشروط يعبر عن موافقته
      على الشروط والتعديلات المستحدثة عليها.
    </p>
    <p>
      4/4 يقر المستفيد بموجب هذه الاتفاقية تفويض مدفوع في استخدام أي من أو جميع
      معلومات التعريف والبيانات الشخصية الخاصة بك التي تم جمعها من خلال المواقع
      الإلكترونية أو المنصات أو تطبيقات الهواتف المحمولة الخاصة بمدفوع أو التجار
      من وقتٍ لآخر ويجوز استخدامها أو الإفصاح عنها إلى أشخاص آخرين. وقد يشمل ذلك
      مشاركة معلومات التعريف الخاصة بك وأية معلومات أخرى مع أطراف خارجية لأغراض
      التحقق من الائتمان ومكافحة غسل الأموال أو غيرها من الفحوصات المماثلة أو
      لأي غرض آخر تعتبره مدفوع ضروري من أجل تمكين مدفوع من تقديم خدمات الدفع لك
      والالتزام بجميع الأنظمة واللوائح المعمول بها.
    </p>
    <p>
      4/5 يقر المستفيد ويوافق على تحديث معلومات حسابه لدينا ودقتها. ويقر بأن أي
      عنوان سكني أو عنوان بريد إلكتروني أو رقم هاتف جوال يقدمه لنا هو عنوان حالي
      يخص المستفيد. ويتحمل المستفيد مسؤولية إخطار مدفوع بشكل فوري عند تغيير أي
      من المعلومات الواردة أعلاه وكما يحق لشركة مدفوع طلب تحديث بيانات المستخدم
      أو طلب بيانات إضافية سواءً من المستخدم أو أية أطراف أخرى على سبيل المثال
      (سمة).
    </p>
    <p>
      4/6 يقر المستفيد بأنه سيقوم بسداد جميع التزاماته المالية الناجمة عن عمليات
      الشراء الخاصة به، وفقاً لشروط الدفع المتفق عليها وقت الشراء.
    </p>
    <p>
      4/7 يقر المستفيد أن الشركة تمتلك الحق في أي وقت، دون الحاجة لإخطاره، ببيع،
      أو إسناد، أو نقل ملكية حسابه، أو أي مبالغ مستحقة على حسابه، أو عقده معنا،
      أو أي من حقوقنا أو التزاماتنا تجاهه بموجب حسابه أو بموجب هذه الشروط إلى أي
      شخص أو كيان آخر، وفي هذه الحالة تنتقل كافة الحقوق والالتزامات المتعلقة
      بالحساب بموجب هذه الشروط إلى هذا الشخص أو الكيان.
    </p>
    <p>
      <strong><label class="heading">(5) التسجيل وإنشاء حساب:</label></strong>
    </p>
    <p>
      5/1 حتى يتسنى لك الاستفادة من خدمات مدفوع، عليك أولاً استكمال بيانات
      التسجيل الخاصة بنظام مدفوع وفتح حساب مدفوع حسب المتطلبات الواردة في صفحة
      التسجيل.
    </p>
    <p>
      5/2 يلتزم المستفيد بتقديم معلوماته حسب متطلبات البنك المركزي السعودي (اعرف
      عميلك) والتي تتضمن الاسم بالكامل، ورقم الهوية الوطنية أو رقم الإقامة
      بالمملكة العربية السعودية، والعنوان الوطني، والبريد الإلكتروني، ورقم
      الهاتف، والعمر، وأي معلومات أخرى تقررها مدفوع من وقت لآخر أو تقررها
      الأنظمة المرعية. ويقر المستفيد على صحة المعلومات المقدمة ويتحمل مسؤولية أي
      خطأ في المعلومات، ويقر بموافقته على حفظ المعلومات واستخدامها وفقاً لهذه
      الأحكام وأحكام سياسة الخصوصية الخاصة بمدفوع.
    </p>
    <p>
      5/3 يفوض المستفيد مدفوع بأن تحصل على ما يلزم أو تحتاج إليه من معلومات، من
      الشركة السعودية للمعلومات الائتمانية (سمة). كما يوافق على أن تفصح الشركة
      عن المعلومات الخاصة به وبحسابه المذكور أو أي حساب آخر يكون لدى المفوض
      للشركة السعودية للمعلومات الائتمانية (سمة) من خلال اتفاقية العضوية المبرمة
      وقواعد العمل المقرة والخاصة بتبادل المعلومات و/ أو لأي جهة أخرى توافق
      عليها البنك المركزي السعودي (ساما).
    </p>
    <p>
      5/4يتحمل المستفيد كامل المسؤولية عن أي نشاط يجري في إطار حسابه لدى مدفوع،
      ولا يجوز نقل الحساب أو السماح للغير باستخدامه، ويجب على المستفيد الحفاظ
      على كلمة المرور الخاصة بحساب مدفوع، وإخطار مدفوع فوراً في حال فقدان أو
      سرقة كلمة المرور الخاصة به، أو إذا كان يعتقد أنه تم الوصول إلى حسابه من
      قبل الغير بشكل غير مصرح به، كما يتحمل المستفيد مسؤولية أي معاملات تتم
      باستخدام حسابه المسروق ما لم يبادر على الفور بإخطار مدفوع بإيقاف الحساب،
      وتلقيه رسالة من مدفوع تفيد بتأكيد إيقاف الحساب
    </p>
    <p>
      <strong><label class="heading">(6): الخدمات المقدمة:</label></strong>
    </p>
    <p>
      6/1 توفر مدفوع خدمات السداد الكامل للعمليات عن طريق محفظة مدفوع، وخدمة
      التقسيط للمشتريات، وخدمة السداد عن طريق وسائل الدفع المتاحة من أطراف ثالثة
      مثل: مدى &ndash; ماستر كارد &ndash; فيزا &ndash; وغيرها. ويمكن الاستفادة
      من هذه الخدمات عن طريق منصة مدفوع مباشرة -سواء عبر الموقع الإلكتروني أو
      التطبيق &ndash; وكذلك يمكن الاستفادة منها عن طريق المتاجر الإلكترونية أو
      الفعلية المشاركة.
    </p>
    <p>
      6/2 تسمح لك خدمة تقسيط المشتريات من مدفوع بشراء سلع أو خدمات من المتاجر
      المشاركة وفقاً لأحكام وشروط السداد المحددة من قبل مدفوع أثناء عملية الدفع،
      ويحق لنا أن نضع حد إجمالي للدفع عن طريق خدمة تقسيط المشتريات، وهذا الحد هو
      إجمالي المبلغ المتاح لك لعمليات الشراء عن طريق الدفع الآجل وفقاً لتقييمك
      الائتماني لدى مدفوع والشركة السعودية للمعلومات الائتمانية
      (&quot;سمة&quot;). ويحق لنا تعديل الحد الإجمالي للدفع سواءً بالزيادة أو
      النقصان بناءً على تقديرنا.
    </p>
    <p>
      <strong><label class="heading">(7): المدفوعات</label></strong>
    </p>
    <p>
      7/1بموجب هذه الاتفاقية فإنك تقر وتوافق وتفوض مدفوع في سحب مبالغ التقسيط
      المتفق عليها &ndash; بما في ذلك الدفعة الأولى المقدمة - لكل عملية شرائية
      تجريها باستخدام خدمة تقسيط المشتريات، وذلك من محفظة مدفوع الخاصة بك أو
      البطاقة البنكية أو الائتمانية المخزنة في حسابك لدى مدفوع في تاريخ
      الاستحقاق. وتقر بأنك تتحمل مسؤولية التأكد من أن تكون المحفظة الخاصة بك أو
      البطاقة الائتمانية أو البنكية تحتوي على المبالغ اللازمة لسداد المديونية.
    </p>
    <p>
      7/2بموجب هذه الاتفاقية فإنك تقر وتوافق وتفوض مدفوع في التعامل مع جهات أخرى
      فيما يتعلق بخدمات السداد. ولا تقوم مدفوع بتخزين بيانات بطاقات الائتمان في
      نظامها. ومدفوع ليست مسؤولة في حال حصل إشكال من الجهات الأخرى فيما يتعلق
      بدفع المبالغ المستحقة وغيرها. ولا تفرض مدفوع أي رسوم فيما يتعلق بالتعامل
      مع الجهات الأخرى (إلاإذا تم النص على ذلك).
    </p>
    <p>
      7/3 بموجب هذه الاتفاقية، فإنك تقر على تفهمك وتحملك للعواقب المحتملة عند
      استخدام خدمة الدفع الآجل من مدفوع خلافاً للشروط المتفق عليها، وتوافق على
      أحقية مدفوع في حالة تأخرك أو عجزك عن السداد في إخطار سمة، ومنعك من استخدام
      حسابك في مدفوع أو الحد منه، مع بقائك في جميع الأحوال مسؤولاً مسؤولية كاملة
      عن المبالغ المستحق دفعها لمدفوع.
    </p>
    <p>
      7/4بموجب هذه الاتفاقية، فإنك تقر أنه في حين أن الشركة قد تساعدك بالقدر
      المستطاع في عمليات استرداد المدفوعات وإعادة المبالغ من المتاجر في بعض
      الحالات، إلا أن هذا الدور لا ينطوي عليه أية التزام على مدفوع فيما يتعلق
      باستردادك لقيمة المدفوعات المختلف عليها مع التاجر.
    </p>
    <p>
      7/5 يحق لشركة مدفوع قبول أو رفض طلب الشراء باستخدام خدماتها وفق تقديرها
      المطلق، وتخطر المستفيد عند رفض طلب الشراء.
    </p>
    <p>
      7/6 يحق لشركة مدفوع سحب موافقتها على عملية شرائية معتمدة في أي وقت في حالة
      الاشتباه بالاحتيال أو في حالة النزاع أو لأي أسباب منصوص عليها في
      الاتفاقية، ويتم سحب موافقة مدفوع تلقائياً إذا تم تغيير أي من شروط المبيعات
      من جانب التاجر فيما يتعلق بالعملية الشرائية المعتمدة دون موافقة مدفوع.
    </p>
    <p>
      7/7 يحق للشركة إيقاف خدمات المستفيد وإيقاف عملية دخول المستفيد على الموقع
      الإلكتروني، أو التطبيق، أو خدماته، أو غيره، أو تحديد الدخول، أو قصره، في
      حال ثمة شكوك لدى مدفوع بأن أية معلومة من المعلومات التي قدمها المستفيد هي
      معلومة غير صحيحة، أو غير دقيقة، أو غير كاملة، أو غير محدثة، أو في حال
      الاشتباه بغسل الأموال أو أية عمليات مشبوهة ويحق لمدفوع إجراء عمليات
      التحقيق والتحري عند الاشتباه بالاحتيال أو إساءه الاستعمال لأنظمة مدفوع
      ويشمل ذلك التعامل مع جهة ثالثة لهذا الغرض. دون إخلال بأية حقوق أخرى
      وتعويضات مكفولة لمدفوع وفق الشروط والأحكام هذه أو بمقتضى الأنظمة المرعية.
    </p>
    <p>
      7/8 في جميع الأحوال يحق لمدفوع أن يلزمك بالدفع الفوري للرصيد المستحق عليك
      في حال التخلف عن سداد أي مبالغ لمدفوع.
    </p>
    <p>
      7/9 يحق لمدفوع التعامل مع أطراف ثالثة بغرض التعامل مع حالات تأخر المستفيد
      عن الدفع، ويشمل ذلك على سبيل المثال لا الحصر الأطراف الثالثة التي تعمل في
      أنشطة تحصيل الديون أو شراء الديون.
    </p>
    <p>
      7/10تلتزم مدفوع بإعفائك من المبالغ المطالب بها لصالحها في حالة الوفاة او
      العجز الكلي وذلك خلال مدة أقصاها ثلاثين يوم من تاريخ استلام المستندات ذات
      العلاقة المثبتة لذلك وإعادة ما تم خصمه بالزيادة من تاريخ الوفاة او العجز
      الكلي، ويستثنى من ذلك:
    </p>
    <p>
      7/10/1 تعمد المستفيد إصابة ذاته، او محاولته للانتحار &ndash; سواء كان
      عاقلاً &ndash; او مختل عقلياً- في ذلك الوقت.
    </p>
    <p>7/10/2 الكوارث الطبيعية.</p>
    <p>
      7/10/3 قرارات المحكمة او السلطة القضائية المختصة بموجب الأنظمة السارية في
      المملكة العربية السعودية.
    </p>
    <p>7/10/4 تعاطي الكحول او المخدرات او العقاقير غير النظامية.</p>
    <p>
      7/10/5 الاشتراك او التدريب على اي رياضة، او منافسة خطرة كالاشتراك في
      سباقات الخيل او سباقات السيارة.
    </p>
    <p>7/10/6 وفاة او إصابة ناشئة عن طبيعة العمل.</p>
    <p>
      <strong><label class="heading">(8): العروض والمكافآت:</label></strong>
    </p>
    <p>
      8/1 من وقت لآخر، قد تقرر الشركة إجراء عروض ترويجية لبعض أو كل المتاجر،
      وذلك عن طريق تقديم مكافآت تحفيزية لإجراء المشتريات من بعض أو كل المتاجر أو
      لتنشيط المنصة في حال موافقة المتاجر المشتركة. وقد تكون تلك المكافآت على
      شكل استرداد جزء أو كامل مبلغ المشتريات المجراة عن طريق خدمات مدفوع، وذلك
      بإيداع مبالغ في محفظة مدفوع الخاصة بالمستفيد. يقر المستفيد بأن تلك
      المكافآت والمبالغ ليست قابلة للاسترداد النقدي، وخاضعة لفترة صلاحية محددة
      من قبل مدفوع.
    </p>
    <p>8/2 الشروط الخاصة في الاسترداد النقدي بمحفظة مدفوع الخاصة بك:</p>
    <p>
      8/2/1 يقر المستفيد بأنه يتم الحصول على الاسترداد النقدي على الطلبات
      المكتملة فقط، ويستثنى منها قيمة الضريبة المضافة، رسوم الخدمات ونقاط البيع.
    </p>
    <p>
      8/2/2 يقر المستفيد بأن الاسترداد النقدي في محفظته لدى مدفوع غير قابل لسداد
      التزاماته بمواجهة مدفوع، وينحصر استخدام الاسترداد النقدي عند اجراء عملية
      شراء جديدة عن طريق خدمات مدفوع فقط.
    </p>
    <p>
      8/2/3 يقر المستفيد بأن لمدفوع كامل الحق في استيفاء مبلغ القسط من المحفظة
      الخاصة به لدى مدفوع عند تأخره عن سداد القسط لمدة ثلاث أيام من تاريخ
      استحقاقه.
    </p>
    <p>
      8/2/4 يقر المستفيد بأن مدة صلاحية الاسترداد النقدي في محفظته لدى مدفوع هو
      (6) ستة أشهر، كما يحق لمدفوع وفقاً لتقديرها المطلق التعديل على مدة
      الصلاحية من حين لأخر.
    </p>
    <p>
      8/2/5 يحق لمدفوع ووفقاً لتقديرها المطلق بوضع حد أعلى لقيمة الاسترداد
      النقدي.
    </p>
    <p>
      8/2/6 تحتفظ مدفوع بالحق في عدم إضافة قيمة أي استرداد نقدي مكتسب أو حذف أي
      استرداد نقدي متراكم على محفظتك في مدفوع؛ حال قيامك بإساءة استخدام خدمات
      مدفوع.
    </p>
    <p>
      8/2/7 تحتفظ مدفوع بالحق ووفق تقديرها المطلق بعدم تفعيل الاسترداد النقدي
      على العمليات الشرائية للفئة مختارة من التجار.
    </p>
    <p>
      8/2/8 الاسترداد النقدي له قيمة نقدية؛ ولا يمكن استردادها إلا في صورة رصيد
      يضاف إلى محفظتك.
    </p>
    <p>
      8/2/9 يجوز لنا تعديل الشروط المنصوص عليها في هذا البند (8/2) أو إلغاء
      اكتسابك للاسترداد النقدي في أي وقت على أن نبلغك بذلك طبقاً لشروط الإخطار
      المنصوص عليها في هذه الاتفاقية.
    </p>
    <p>
      <strong
        ><label class="heading">(9): السرية وحماية البيانات:</label></strong
      >
    </p>
    <p>
      الشركة تستخدم بيانات المستفيد الشخصية بالطريقة المبينة في سياسة الخصوصية
      التي تعتبر جزءً لا يتجزأ من هذه الاتفاقية، وعلى النحو المبين بمزيد من
      التفصيل في تلك الاتفاقية؛ وبالتالي فإنك بمجرد استخدامك لخدمات مدفوع، فأنت
      تقر على أنك توافق لمدفوع على جمع واستخدام بياناتك الشخصية بالطريقة المبينة
      في سياسة الخصوصية أو هذه الاتفاقية أو التغييرات التي تطرأ عليهما.
    </p>
    <p>
      <strong><label class="heading">(10): حدود المسؤولية:</label></strong>
    </p>
    <p>
      10/1 تعتبر مدفوع بمثابة منصة مستقلة، لا تشارك في المعاملات التي تجري بين
      المستفيد والتاجر أو التعاملات الأخرى المشاركة ذات الصلة، ومن ثم لا تتحمل
      مدفوع المسؤولية بأي حال من الأحوال عن أي وضع من الأوضاع المرتبطة بالتجار
      ولا تتحمل أي مسؤولية عن أي إخفاق يرتبط بمنتجات التجار، أو طراز المنتجات،
      او علامتها، أو مصداقيتها، أو كفاءتها أو أصليتها أو توافرها أو قانونيتها.
    </p>
    <p>
      10/2ويعتبر التجار من الغير في هذه الاتفاقية وهم بمثابة مقدمي خدماتٍ
      مستقلين ومتعاقدين فقط مع مدفوع ولا يجوز اعتبارهم عاملين لدى مدفوع أو
      ممثلين لها أو وكلاء عنها. كما أن مدفوع لا تؤدي المهام بنفسها، بل تتولى
      إدارة عملية تنفيذ مهمة الدفع بالآجل من خلال منصتها الإلكترونية، ويتحمل
      التجار المسئولية الكاملة عن كافة التعاملات التي تجري بينهم وبين المستفيد
      أو التعاملات الأخرى ذات الصلة، وفي حالة نشوء نزاع بين أي طرف من المشاركين
      في هذه المعاملات، يتعهد كل من المستفيدين والتجار على إعفاء مدفوع من أي
      مسؤولية ناتجة أو مرتبطة عن أية نزاعاتٍ بين أي طرف بأي حال من الأحوال.
      والتعهد بإعفاء مدفوع (ووكلائها وموظفيها) من المطالبات، والطلبات،
      والمسؤوليات والتعويضات أياً كان نوعها أو طبيعتها، وسواء كانت معروفة أو غير
      معروفة، وسواء كان مفصح عنها أم غير مفصح عنها، والتي تنشأ عن أو تتعلق بأي
      شكل من الأشكال بتلك النزاعات.
    </p>
    <p>
      10/3 بموجب الموافقة على هذه الاتفاقية تقر بعلمك وتفهمك باحتمال وقوع مخاطر
      من التجار أو أخطاء منهم أو من مقدمي الخدمة من الغير، بالتالي يكون استخدام
      الخدمات أمر يقع على مسؤولية المستفيد ووفقاً لقراره، ولا تتحمل مدفوع أي
      مسؤولية تجاهه.
    </p>
    <p>
      10/4 ولا تتحمل مدفوع أية مسئولية تجاهك أو تجاه أي شخص آخر، عما قد ينشأ من
      أضرارٍ مباشرة، أو غير مباشرة، أو عارضة، أو خاصة، أو جزائية، أو عقابية، أو
      تبعية، حتى لو أبلغت مدفوع ما يفيد باحتمال وقوع تلك الأضرار، كما لا تتحمل
      مدفوع المسؤولية عن أية أضرار أو التزاماتٍ أو خسائر نشأت من واقع استخدامكم
      أو اعتمادكم على الخدمات أو جراء عدم أمكانية دخولكم على الخدمات أو
      استخدامها أو من واقع أية معاملة أو علاقة نشأت بينكم وبين أي تاجر من
      التجار، بل وحتى لو ورد لمدفوع ما يفيد باحتمال وقوع تلك الأضرار. ولا تتحمل
      مدفوع أيضا المسئولية عن أي تأخير أو إخفاق في التنفيذ، كما لا تتحمل
      المسئولية في حالة احتمال عدم حيازة التاجر تصريح مهني أو تخصصي بالعمل.
    </p>
    <p>
      10/5 يتم تقديم الخدمات كما هي معروضة وحسبما هو متاح منها، وتعفى مدفوع بل
      وتبرأ من كافة الإقرارات والضمانات الصريحة منها أو الضمنية أو القانونية
      التي لم يرد نصها صراحة في الشروط الماثلة، ومن ذلك الضمانات المعروضة ضمناً
      بشأن قدرة التاجر وملاءمة خدماته لغرض معين وعدم إخلاله بها، كما لا تقدم
      مدفوع أي إقرار أو ضمان بشأن مصداقية الخدمات أو البضائع أو جودتها أو
      استدامتها أو توافرها أو مالكها الأصلي هي أو أية خدمات تطلب من خلال استخدام
      خدمات مدفوع، كما لا تضمن مدفوع عدم تعرض الخدمات للتعطيل أو خلوها من الخطأ.
      ولهذا فأنت تقر وتوافق على تحملك حصرا كامل المسئولية الناشئة عن استخدامك
      لأي من الخدمات التي تقدمها مدفوع، وذلك إلى أقصى حد يسمح به النظام واجب
      التطبيق.
    </p>
    <p>
      10/6 لا تتحمل مدفوع المسؤولية عن أي تأخير أو إعاقة أو إخفاق في توفير
      الخدمات، إن كان السبب فيها يرجع إلى حدوث قوة قاهرة أو ما شابهه من أحداث
      تخرج عن نطاق تحكمنا أو سيطرتنا وكان من شأنها منع عملية تقديم الخدمة أو
      إعاقتها.
    </p>
    <p>
      10/7أنت تقر وتوافق بموجب هذه الاتفاقية على إبراء ذمة شركة مدفوع وأي من
      شركاتها التابعة ومسؤوليها ومديريها وموظفيها ووكلائها من وضد أية مطالبات،
      أو مسؤوليات، أو أضرار، أو خسائر، أو نفقات، أو تعويضها وإخلاء مسؤوليتها عن
      ذلك، بما في ذلك على سبيل المثال لا الحصر، الرسوم القانونية والمحاسبية
      الناشئة عن أو بأي طريقة تتعلق بما يلي:
    </p>
    <p>
      10/7/1 وصولك إلى حساب مدفوع أو خدمات الدفع من مدفوع أو استخدامها أو عدم
      القدرة على استخدامها.
    </p>
    <p>10/7/2 إهمالك أو سوء سلوكك.</p>
    <p>10/7/3 انتهاكك لأي نظام معمول به.</p>
    <p>
      10/7/4 إخفاقك في تقديم المعلومات الصحيحة والدقيقة والحديثة والكاملة فيما
      يتعلق بحساب مدفوع الخاص بك والاحتفاظ به.
    </p>
    <p>
      10/8إذا لم توافق على أي من هذه الشروط والأحكام في الاتفاقية، فيكون عليك
      عدم الدخول إلى الموقع وعدم استخدام خدمات مدفوع.
    </p>
    <p>
      <strong
        ><label class="heading">(11): حقوق الملكية الفكرية:</label></strong
      >
    </p>
    <p>
      11/1 يعتبر المحتوى الوارد على الموقع الإلكتروني أو التطبيق الخاص بمدفوع
      بمثابة ملكية فكرية خاصة لمدفوع أو بمرخصيه، ويخضع هذا المحتوى للحماية بموجب
      حق المؤلف وحقوق العلامات التجارية وبراءات الاختراع وغير ذلك من حقوق
      الملكية الفكرية، ومن ذلك على سبيل المثال لا الحصر، التصاميم، والشعارات،
      والأيقونات، والصور، والمقاطع الصوتية والمرئية، والبرمجة، والشفرات
      الإلكترونية، وجميع البيانات الأخرى التي تحتويها المنصة.
    </p>
    <p>
      11/2يحظر على المستفيد أو أي زائر لمنصة مدفوع استخدام أية علامة تجارية أو
      أي حقوق ملكية فكرية تخص مدفوع، وذلك من أي جزء من الخدمات، كما يحظر عليه
      إعادة إنتاج الخدمات أو تعديلها أو إجراء أية عملية إعداد، أو نشر، أو تنفيذ،
      أو إرسال، أو عرض، أو بث، أو غير ذلك من وسائل استغلال الخدمات، ما لم تسمح
      مدفوع بذلك أو تأذن به صراحة بشكل كتابي. كما لا يجوز إجراء أعمال تفكيك
      لنظام الخدمات أو أية هندسية عكسية أو إعادة تشكيل لها، أو القيام بربط أي
      جزء من الخدمات بأي نظام عاكس، أو تأطيره، أو وضع أية برامج ،أو نصوص لغرض
      إزالة أي جزء من الخدمات أو فهرستها أو مسحها أو إجراء غير ذلك من عمليات
      استخراج للبيانات منه، أو القيام بتحميل عملية التشغيل أو العمل الوظيفي لأي
      شكل من أشكال الخدمات بأحمال لا مبرر لها أو القيام بإعاقتها أو محاولة
      الحصول على إمكانية الدخول عليها دون تصريح بذلك أو العمل على الإضرار
      بالخدمات أو ما يرتبط بها من أنظمة أو شبكات.
    </p>
    <p>
      <strong
        ><label class="heading"
          >(12): التغيير أو التعديل على الاتفاقية:</label
        ></strong
      >
    </p>
    <p>
      12/1 يحق لمدفوع بعد إشعار المستفيد بمدة لا تقل عن 15 يوم بتعديل، أو تغير،
      أو إضافة، أو حذف المواد من هذه الاتفاقية في أي وقت لآخر دون أخذ موافقة من
      المستفيد، ويعد استمرار المستفيد في استخدام الخدمات محل هذه الاتفاقية
      موافقة ضمنية منه على أي تغيير يطرأ على هذه الاتفاقية.
    </p>
    <p>
      12/2 لا يحق للمستفيد التنازل عن أي حقوق أو التزامات منصوص عليها في هذه
      الاتفاقية دون الحصول على موافقة خطية مسبقة من الشركة.
    </p>
    <p>
      12/3 يحق لمدفوع أن تتنازل عن هذه الاتفاقية إلى طرف ثالث دون أخذ موافقة من
      المستفيد.
    </p>
    <p>
      <strong
        ><label class="heading">(13): سريان وانتهاء الاتفاقية:</label></strong
      >
    </p>
    <p>
      13/1 يبدأ العمل بأحكام هذه الاتفاقية اعتباراً من وقت وصول المستفيد إلى
      منصة مدفوع عبر أي قناة من قنوات مدفوع، وتنطبق على أي إجراء يقوم به
      المستفيد خلال تواجده في المنصة.
    </p>
    <p>
      13/2 يجوز للمستفيد إنهاء هذه الاتفاقية في أي وقت، من خلال التوقف عن
      استخدام منصة مدفوع بشكل كامل، ومع ذلك تبقى أي التزامات سابقة في ذمة
      المستفيد خاضعة لهذه الاتفاقية.
    </p>
    <p>
      13/3 يحق لمدفوع إنهاء خدمات مدفوع وهذه الاتفاقية لأي سبب وفي أي وقت وذلك
      بعد إشعار المستفيد بمدة لا تقل عن 15 يوم، ويقر ويوافق المستفيد على إنهاء
      هذه الاتفاقية بسبب أو بدون سبب مع عدم التزماها بإبلاغه بسبب إنهاء
      الاتفاقية دون أدنى التزام على مدفوع تجاه المستفيد.
    </p>
    <p>
      13/4 يجوز للمستفيد طلب إغلاق حساب مدفوع الخاص به في أي وقت باتباع
      التعليمات الموجودة على الموقع الإلكتروني أو التطبيق إذا لم يكن هناك أية
      مبالغ مستحقة عليه لصالح الشركة بخصوص خدمات الدفع من مدفوع، ويبقى المستفيد
      مسؤولًا عن جميع الالتزامات المتعلقة بحسابه حتى بعد إغلاق الحساب.
    </p>
    <p>
      <strong
        ><label class="heading"
          >(14): عناوين المراسلات والإشعارات:</label
        ></strong
      >
    </p>
    <p>
      14/1عند انشاء حساب، فإنك تقر وتوافق على أنك ستتواصل معنا الكترونياً،
      وبالتالي توافق على استلام رسائل دورية من جانبنا وسوف يتواصل معك فريق مدفوع
      عبر البريد الإلكتروني أو قد يرسل لكم معلومات عبر رسائل نصية قصيرة، وكذلك
      القيام بنشر إخطارات على الموقع الإلكتروني كجزء من إدارة الأعمال المعتادة
      والتي ترتبط بعملية استخدامك للخدمات، وإنك تقر وتوافق بأن اختياركم عدم
      تطبيق وسائل التواصل المذكورة هو أمر من شأنه إحداث تأثير على عملية
      استخدامكم للخدمات.
    </p>
    <p>
      14/2يجب أن تكون جميع الإخطارات والمراسلات الأخرى بموجب هذه الاتفاقية
      كتابةً عبر المراسلات إلى البريد الإلكتروني للشركة الموضح على الموقع
      الإلكتروني.
    </p>
    <p>
      14/3يلتزم المستفيد إخطارنا فوراً بأي تغيير في عنوان بريده الإلكتروني أو
      أية معلومات اتصال أخرى يزود بها مدفوع.
    </p>
    <p>
      <strong><label class="heading">(15): القانون المنطبق:</label></strong>
    </p>
    <p>
      تخضع هذه الشروط والأحكام والسياسات الواردة في هذه الاتفاقية وتفسر وفقاً
      لأنظمة المملكة العربية السعودية.
    </p>
    <p>
      <strong><label class="heading">(16): حل النزاعات:</label></strong>
    </p>
    <p>
      تتم تسوية أي نزاع أو خلاف أو مطالبة تنشأ عن أو تتعلق بهذه الاتفاقية، أو
      خرقها، أو إنهائها، أو بطلانها، عن طريق المحكمة أو اللجنة القضائية المختصة
      في مدينة الرياض، المملكة العربية السعودية.
    </p>
    <p>
      <strong><label class="heading">(17): الاتفاقية الكاملة:</label></strong>
    </p>
    <p>
      تشكل هذه الاتفاقية، إلى جانب أي تعديلات وأي اتفاقيات إضافية تتعلق باستخدام
      المنصة والخدمات، الاتفاقية الكاملة بينك وبين مدفوع.
    </p>
    <p>
      <strong><label class="heading">(18): معلومات التواصل:</label></strong>
    </p>
    <p>
      إذا كان لديك أي أسئلة بخصوص هذه الاتفاقية أو منصة مدفوع، يرجى منك زيارة
      صفحة &quot;المساعدة&quot; للحصول على معلومات الاتصال الخاصة بنا.
    </p>
  </div>
</div>
<div class="terms-data" *ngIf="currentLang == ApplicationLanguages.En">
  <div class="container">
    <p class="heading center first-item">
      <strong>Paid Agreement (Terms and Conditions)</strong>
    </p>

    <p class="heading center">
      <strong>Effective Date 04/16/2024</strong>
    </p>
    <p></p>
    <p>
      <br />
    </p>
    <p>
      <strong><label class="heading">Introduction:</label></strong>
    </p>
    <p>
      Madfoo Arabia for Financial Technology Company, an unlisted joint stock
      company, commercial registration number (1010747077) registered in Riyadh,
      Kingdom of Saudi Arabia, hereinafter referred to as (&ldquo;We, the
      Company, Madfoo or the Platform&rdquo;), is a company authorized by the
      Central Bank to practice deferred payment (BNPL) (&ldquo;Services&rdquo;),
      through Madfoo&rsquo;s website, Madfoo&rsquo;s application, or electronic
      or physical stores that allow payment through Madfoo (&ldquo;Madfoo
      Channels&rdquo;); Madfoo&rsquo;s role is limited to facilitating the
      process of paying the value of purchases made between individuals and
      stores, and its responsibility does not extend to any of the usual
      responsibilities of individuals or stores towards each other.
      Madfoo&rsquo;s platform is not a store, and is not responsible for the
      accuracy of the information displayed by stores or the regularity of the
      products or services provided by participating stores. Madfoo&rsquo;s
      platform is also not an entity that verifies the information or identities
      of individuals benefiting from the service. In all cases, stores and
      individuals bear full responsibility related to the purchases made between
      them on the platform
    </p>
    <p>
      Whereas you wish to benefit from the services provided by Madfoo, you
      acknowledge - once you access or use any of Madfoo&rsquo;s services or
      register a user account - that you have reviewed and understood these
      terms and conditions and agreed to the obligations stipulated therein, and
      the terms and conditions are considered a binding agreement concluded
      between the two parties in accordance with the following:
    </p>
    <p>
      <strong
        ><label class="heading"
          >1- The importance of the introduction:</label
        ></strong
      >
    </p>
    <p>
      The above preamble is an integral part of this Agreement and shall be
      construed in accordance with it.
    </p>
    <p>
      <strong
        ><label class="heading">2. Parties to the Agreement:</label></strong
      >
    </p>
    <p>
      2/1 This Agreement is considered a contract concluded between Madfoo and
      anyone who benefits from or accesses Madfoo services
      (&ldquo;Beneficiary&rdquo;) through any of Madfoo&rsquo;s channels.
    </p>
    <p>
      2/2 The merchant from whom the beneficiary purchases goods or services
      using Madfoo services is not considered a party to this agreement, and any
      terms in force between the merchant and the beneficiary or the merchant
      and Madfoo are independent of this agreement.
    </p>
    <p>
      <strong><label class="heading">3- Definitions:</label></strong>
    </p>
    <p>
      For the purposes of applying this Agreement, the following words and
      phrases - wherever they appear in the Agreement - shall have the meanings
      indicated opposite each of them, unless the context requires otherwise:
    </p>
    <p>
      3/1 Agreement: means the terms and conditions set forth in this Agreement,
      including the Madfoo Privacy Policy and any updates thereto from time to
      time, and any other terms and conditions referred to as part of this
      Agreement.
    </p>
    <p>
      3/2 The Company or Madfoo: means Madfoo Limited Company, Commercial
      Registration No. (1010747077) registered in the city of Riyadh, in the
      Kingdom of Saudi Arabia (&ldquo;Madfoo&rdquo;), and may be referred to as
      the pronoun (&ldquo;we&rdquo;) or the collective &ldquo;noon&rdquo; or any
      other similar pronouns in this agreement.
    </p>
    <p>
      3/3 Beneficiary: is the natural person who uses any of the paid services
      to complete a purchase with any of the participating stores, and may be
      referred to as &ldquo;you&rdquo; or the second person pronoun (your) or
      any other similar pronouns in this agreement.
    </p>
    <p>
      3/4 The merchant or participating stores: This means the online store,
      applications, or physical stores that provide payment services via Madfoo,
      or the stores displayed on the Madfoo platform, in which the merchant
      displays his goods, services, products, and others, in accordance with the
      agreement between him and Madfoo.
    </p>
    <p>
      3/5 Paid Channels: means the means provided by the merchant to the
      beneficiary to enable the beneficiary to choose Paid as a payment method,
      including, but not limited to, the barcode shown on the invoice that
      transfers the beneficiary to the payment page in Paid.
    </p>
    <p>3/6 Website: means the website of Madfu Company WWW.MADFU.COM.SA</p>
    <p>
      3/7 Application: means a paid application available on the (Apple App
      Store) or (Google Play Store) or any other operating system in which the
      paid platform application is available.
    </p>
    <p>
      3/8 Paid Account: means the account created by the customer in order to be
      able to use the payment services from Madfoo in accordance with the terms
      and conditions stipulated in this agreement.
    </p>
    <p>
      3/9 Madfoo Wallet: It is an electronic financial wallet linked to your
      Madfoo account, and you can deposit amounts of money into it or pay for
      purchases from participating stores.
    </p>
    <p>
      3/10 Payment services from Madfoo: Facilitating deferred payment
      procedures for individuals through full payment or installments.
    </p>
    <p>
      <strong
        ><label class="heading"
          >(4): Beneficiary&rsquo;s declarations:</label
        ></strong
      >
    </p>
    <p>
      4/1 The beneficiary acknowledges that he has full legal capacity, which
      enables him to conclude this agreement in accordance with the terms and
      conditions stipulated, and that he is qualified to perform the legal
      actions necessary to open an account or use paid services.
    </p>
    <p>
      4/2 The beneficiary acknowledges that accessing the Madfoo platform or
      using any of Madfoo&rsquo;s services or clicking to agree to the terms and
      conditions stipulated in this agreement constitutes his acknowledgment of
      concluding this agreement electronically between him and Madfoo in
      accordance with the regulations of the Kingdom of Saudi Arabia.
    </p>
    <p>
      4/3 The beneficiary acknowledges his responsibility to review the
      information published on our application or website regularly and to
      review this agreement regularly before using any of the paid services. His
      continued use of the services on these terms expresses his agreement to
      the terms and amendments thereto.
    </p>
    <p>
      4/4 The Beneficiary hereby authorizes Paid to use any or all of your
      identification information and personal data collected through
      Paid&rsquo;s websites, platforms or mobile applications from time to time
      and may be used or disclosed to other persons. This may include sharing
      your identification information and any other information with third
      parties for the purposes of credit verification, anti-money laundering or
      other similar checks or for any other purpose Paid deems necessary to
      enable Paid to provide the Payment Services to you and to comply with all
      applicable laws and regulations.
    </p>
    <p>
      4/5 The beneficiary acknowledges and agrees to update and accurately use
      his account information with us. He acknowledges that any residential
      address, email address or mobile phone number he provides to us is a
      current address of the beneficiary. The beneficiary is responsible for
      notifying Madfa immediately upon any change in any of the above
      information and Madfa has the right to request updating the user&rsquo;s
      data or request additional data from the user or any other parties, for
      example (Sama).
    </p>
    <p>
      4/6 The beneficiary acknowledges that he will pay all his financial
      obligations resulting from his purchases, in accordance with the payment
      terms agreed upon at the time of purchase.
    </p>
    <p>
      4/7 The beneficiary acknowledges that the company has the right at any
      time, without the need to notify him, to sell, assign, or transfer
      ownership of his account, or any amounts due on his account, or his
      contract with us, or any of our rights or obligations towards him under
      his account or under these terms to any other person or entity, and in
      this case all rights and obligations related to the account under these
      terms shall be transferred to this person or entity.
    </p>
    <p>
      <strong
        ><label class="heading"
          >(5) Registration and account creation:</label
        ></strong
      >
    </p>
    <p>
      5/1 In order to benefit from the paid services, you must first complete
      the registration data for the paid system and open a paid account
      according to the requirements stated on the registration page.
    </p>
    <p>
      5/2 The beneficiary is obligated to provide his information in accordance
      with the requirements of the Saudi Central Bank (Know Your Customer),
      which includes the full name, national ID number or residence number in
      the Kingdom of Saudi Arabia, national address, email, phone number, age,
      and any other information determined by Madfoo from time to time or
      determined by the applicable regulations. The beneficiary acknowledges the
      accuracy of the information provided and bears responsibility for any
      error in the information, and acknowledges his consent to save and use the
      information in accordance with these terms and the terms of Madfoo&rsquo;s
      privacy policy.
    </p>
    <p>
      5/3 The Beneficiary authorizes Paid to obtain the necessary or required
      information from the Saudi Credit Bureau (SIMAH). He also agrees that the
      company will disclose information about him and his aforementioned account
      or any other account that the authorized person has with the Saudi Credit
      Bureau (SIMAH) through the concluded membership agreement and the approved
      work rules related to the exchange of information and/or to any other
      party approved by the Saudi Central Bank (SAMA).
    </p>
    <p>
      5/4 The beneficiary bears full responsibility for any activity that takes
      place within the framework of his account with Madfoo. The account may not
      be transferred or allowed to be used by others. The beneficiary must
      maintain the password for the Madfoo account and immediately notify Madfoo
      in the event of loss or theft of his password, or if he believes that his
      account has been accessed by others in an unauthorized manner. The
      beneficiary also bears responsibility for any transactions that take place
      using his stolen account unless he immediately notifies Madfoo of the
      suspension of the account and receives a message from Madfoo confirming
      the suspension of the account.
    </p>
    <p>
      <strong><label class="heading">(6): Services provided:</label></strong>
    </p>
    <p>
      6/1 Madfa provides full payment services for transactions through Madfa
      wallet, installment service for purchases, and payment service through
      payment methods available from third parties such as: Mada - MasterCard -
      Visa - and others. These services can be used directly through Madfa
      platform - whether through the website or the application - and they can
      also be used through participating online or physical stores.
    </p>
    <p>
      6/2 The Madfoo&rsquo;s installment purchase service allows you to purchase
      goods or services from participating stores according to the payment terms
      and conditions specified by Madfoo during the payment process, and we have
      the right to set a total payment limit through the installment purchase
      service, and this limit is the total amount available to you for purchases
      via deferred payment according to your credit rating with Madfoo and the
      Saudi Credit Bureau (&ldquo;SIMAH&rdquo;). We have the right to amend the
      total payment limit, whether by increasing or decreasing it based on our
      discretion.
    </p>
    <p>
      <strong><label class="heading">(7): Payments</label></strong>
    </p>
    <p>
      7/1 Under this Agreement, you acknowledge, agree and authorize Madfa to
      withdraw the agreed installment amounts - including the down payment - for
      each purchase you make using the Purchase Installment Service, from your
      Madfa wallet or bank or credit card stored in your account with Madfa on
      the due date. You acknowledge that you bear the responsibility of ensuring
      that your wallet or credit or bank card contains the amounts necessary to
      repay the debt.
    </p>
    <p>
      7/2 Under this agreement, you acknowledge, agree and authorize Madfoo to
      deal with other parties in relation to payment services. Madfoo does not
      store credit card data in its system. Madfoo is not responsible in the
      event of a problem from other parties regarding payment of due amounts and
      others. Madfoo does not impose any fees in relation to dealing with other
      parties (unless otherwise stated).
    </p>
    <p>
      7/3 Under this agreement, you acknowledge your understanding and tolerance
      of the potential consequences when using the Madfoo deferred payment
      service in violation of the agreed terms, and you agree to Madfoo&rsquo;s
      entitlement in the event of your delay or failure to pay in a notification
      to Sama, and to prevent or limit your use of your Madfoo account, while
      you remain in all cases fully responsible for the amounts due to Madfoo.
    </p>
    <p>
      7/4 Under this Agreement, you acknowledge that while the Company may
      assist you to the extent possible with refunds and returns from stores in
      some cases, this role does not entail any obligation on the part of the
      Payer with respect to your refund of the value of the disputed payments
      with the merchant.
    </p>
    <p>
      7/5 Madfoo Company has the right to accept or reject the purchase request
      using its services at its absolute discretion, and will notify the
      beneficiary when the purchase request is rejected.
    </p>
    <p>
      7/6 Madfaa Company has the right to withdraw its consent to an approved
      purchase transaction at any time in the event of suspected fraud, in the
      event of a dispute, or for any reasons stipulated in the agreement.
      Madfaa&rsquo;s consent shall be automatically withdrawn if any of the
      sales terms are changed by the merchant in relation to the approved
      purchase transaction without Madfaa&rsquo;s consent.
    </p>
    <p>
      7/7 The company has the right to stop the beneficiary&rsquo;s services and
      stop the beneficiary&rsquo;s access to the website, application, services,
      or otherwise, or to limit or restrict access, if Madfo has any suspicions
      that any information provided by the beneficiary is incorrect, inaccurate,
      incomplete, or outdated, or in the event of suspected money laundering or
      any suspicious transactions. Madfo has the right to conduct investigations
      and inquiries when suspected of fraud or misuse of Madfo&rsquo;s systems,
      including dealing with a third party for this purpose. Without prejudice
      to any other rights and compensation guaranteed to Madfo in accordance
      with these terms and conditions or pursuant to applicable regulations.
    </p>
    <p>
      7/8 In all cases, the payee has the right to oblige you to pay the balance
      due from you immediately in the event of failure to pay any amounts to the
      payee.
    </p>
    <p>
      7/9 The Payee has the right to deal with third parties for the purpose of
      dealing with cases of late payment by the Beneficiary, including but not
      limited to third parties engaged in debt collection or debt purchase
      activities.
    </p>
    <p>
      7/10 Madfa is committed to exempting you from the amounts claimed in its
      favor in the event of death or total disability within a maximum period of
      thirty days from the date of receipt of the relevant documents proving
      this, and to return what was deducted in excess from the date of death or
      total disability, with the exception of:
    </p>
    <p>
      7/10/1 The beneficiary deliberately injures himself, or attempts suicide -
      whether he is sane - or mentally ill - at that time.
    </p>
    <p>7/10/2 Natural disasters.</p>
    <p>
      7/10/3 Decisions of the court or the competent judicial authority pursuant
      to the regulations in force in the Kingdom of Saudi Arabia.
    </p>
    <p>7/10/4 Use of alcohol, drugs or illegal drugs.</p>
    <p>
      7/10/5 Participating in or training for any sport or dangerous
      competition, such as participating in horse races or car races.
    </p>
    <p>7/10/6 Death or injury arising from the nature of work.</p>
    <p>
      <strong><label class="heading">(8): Offers and rewards:</label></strong>
    </p>
    <p>
      8/1 From time to time, the Company may decide to conduct promotions for
      some or all of the Stores, by offering incentive rewards for making
      purchases from some or all of the Stores or for activating the Platform if
      the participating Stores agree. These rewards may be in the form of a
      partial or full refund of the amount of purchases made through Madfoo
      Services, by depositing amounts into the Beneficiary&rsquo;s Madfoo
      Wallet. The Beneficiary acknowledges that these rewards and amounts are
      not redeemable in cash, and are subject to a validity period determined by
      Madfoo.
    </p>
    <p>8/2 Special conditions for cashback in your Madfoo wallet:</p>
    <p>
      8/2/1 The beneficiary acknowledges that the cashback is obtained on
      completed orders only, excluding the value-added tax, service fees and
      point of sale fees.
    </p>
    <p>
      8/2/2 The beneficiary acknowledges that the cashback in his wallet with
      Madfoo is not eligible to settle his obligations towards Madfoo, and the
      use of the cashback is limited to making a new purchase through Madfoo
      services only.
    </p>
    <p>
      8/2/3 The beneficiary acknowledges that the payee has the full right to
      collect the installment amount from his personal wallet at Madf if he is
      late in paying the installment for three days from the due date.
    </p>
    <p>
      8/2/4 The beneficiary acknowledges that the validity period of the
      cashback in his wallet with Madfoo is (6) six months, and Madfoo has the
      right, at its absolute discretion, to amend the validity period from time
      to time.
    </p>
    <p>
      8/2/5 The payer has the right, at its absolute discretion, to set a
      maximum limit for the cash back value.
    </p>
    <p>
      8/2/6 Madfa reserves the right not to credit any cashback earned or delete
      any cashback accumulated on your Madfa wallet if you misuse Madfa
      services.
    </p>
    <p>
      8/2/7 Madfa reserves the right, at its sole discretion, not to activate
      cashback on purchases for selected merchants.
    </p>
    <p>
      8/2/8 Cashback has a cash value and can only be redeemed as credit to your
      wallet.
    </p>
    <p>
      8/2/9 We may amend the terms set forth in this clause (8/2) or cancel your
      earning of cashback at any time, provided that we notify you of this in
      accordance with the notification terms set forth in this Agreement.
    </p>
    <p>
      <strong
        ><label class="heading"
          >(9): Confidentiality and data protection:</label
        ></strong
      >
    </p>
    <p>
      The Company uses the Beneficiary&rsquo;s personal data in the manner set
      forth in the Privacy Policy, which is an integral part of this Agreement,
      and as further detailed in that Agreement; therefore, by using the Paid
      Services, you acknowledge that you consent to Paid collecting and using
      your personal data in the manner set forth in the Privacy Policy or this
      Agreement or changes thereto.
    </p>
    <p>
      <strong><label class="heading">(10): Limits of Liability:</label></strong>
    </p>
    <p>
      10/1 Madfa is considered an independent platform, does not participate in
      the transactions taking place between the beneficiary and the merchant or
      other related participating transactions, and therefore Madfa does not
      bear any responsibility in any way for any situation related to the
      merchants and does not bear any responsibility for any failure related to
      the merchants&rsquo; products, or the product model, or its brand, or its
      reliability, or its efficiency, or its originality, or its availability,
      or its legality.
    </p>
    <p>
      10/2 Merchants are considered third parties in this agreement and are
      independent service providers who are contracted only with Madfoo and may
      not be considered as Madfoo&rsquo;s employees, representatives or agents.
      Madfoo does not perform the tasks itself, but rather manages the process
      of implementing the deferred payment task through its electronic platform,
      and merchants bear full responsibility for all transactions that take
      place between them and the beneficiary or other related transactions, and
      in the event of a dispute between any party participating in these
      transactions, both beneficiaries and merchants undertake to exempt Madfoo
      from any liability resulting from or related to any disputes between any
      party in any way. And to undertake to exempt Madfoo (and its agents and
      employees) from claims, demands, liabilities and compensations of any kind
      or nature, whether known or unknown, whether disclosed or undisclosed,
      which arise from or relate in any way to such disputes.
    </p>
    <p>
      10/3 By agreeing to this agreement, you acknowledge your knowledge and
      understanding of the possibility of risks arising from merchants or errors
      from them or from third party service providers. Therefore, the use of the
      services is the responsibility of the beneficiary and according to his
      decision, and Madfoo does not bear any responsibility towards him.
    </p>
    <p>
      10/4 Madfoo shall not be liable to you or to any other person for any
      direct, indirect, incidental, special, punitive, exemplary or
      consequential damages, even if Madfoo has been informed of the possibility
      of such damages, nor shall Madfoo be liable for any damages, obligations
      or losses arising from your use of or reliance on the Services or from
      your inability to access or use the Services or from any transaction or
      relationship established between you and any merchant, even if Madfoo has
      been informed of the possibility of such damages. Madfoo shall also not be
      liable for any delay or failure in implementation, nor shall it be liable
      in the event that the merchant may not have a professional or specialized
      license to work.
    </p>
    <p>
      10/5 The Services are provided as is and as available, and Madfoo is
      exempt and disclaims all representations and warranties, express, implied
      or statutory, that are not expressly stated in these Terms, including
      implied warranties regarding the merchant&rsquo;s ability and suitability
      of its services for a particular purpose and its non-infringement thereof.
      Madfoo does not provide any representation or warranty regarding the
      reliability, quality, sustainability, availability or original owner of
      the Services or Goods or any Services requested through the use of Madfoo
      Services. Madfoo does not guarantee that the Services will not be
      disrupted or free from errors. Therefore, you acknowledge and agree that
      you bear exclusively all responsibility arising from your use of any of
      the Services provided by Madfoo, to the maximum extent permitted by
      applicable law.
    </p>
    <p>
      10/6 Madfoo shall not be liable for any delay, obstruction or failure in
      providing the services, if the cause thereof is due to the occurrence of
      force majeure or similar events beyond our control or control, which would
      prevent or obstruct the process of providing the service.
    </p>
    <p>
      10/7 You hereby acknowledge and agree to release Madfoo Company and any of
      its affiliates, officers, directors, employees and agents from and against
      any claims, liabilities, damages, losses, expenses, compensation and
      disclaimer thereof, including but not limited to, legal and accounting
      fees arising from or in any way related to the following:
    </p>
    <p>
      10/7/1 Your access to, use of, or inability to use a Paid Account or Paid
      Services.
    </p>
    <p>10/7/2 Your negligence or misconduct.</p>
    <p>10/7/3 Your violation of any applicable law.</p>
    <p>
      10/7/4 Your failure to provide and maintain true, accurate, current and
      complete information in connection with your Paid Account.
    </p>
    <p>
      10/8 If you do not agree to any of these terms and conditions in the
      agreement, you must not access the site and not use the paid services.
    </p>
    <p>
      <strong
        ><label class="heading"
          >(11): Intellectual property rights:</label
        ></strong
      >
    </p>
    <p>
      11/1 The content contained on the Madfoo website or application is the
      intellectual property of Madfoo or its licensors, and is subject to
      protection under copyright, trademark rights, patents and other
      intellectual property rights, including, but not limited to, designs,
      logos, icons, images, audio and video clips, programming, electronic
      codes, and all other data contained on the platform.
    </p>
    <p>
      11/2 The beneficiary or any visitor to the Madfoo platform is prohibited
      from using any trademark or any intellectual property rights belonging to
      Madfoo from any part of the services, and is prohibited from reproducing
      or modifying the services or carrying out any process of preparation,
      publication, implementation, transmission, display, broadcast, or other
      means of exploiting the services, unless Madfoo permits or expressly
      authorizes this in writing. It is also prohibited to disassemble the
      services system or any reverse engineering or restructuring of it, or link
      any part of the services to any reflective system, or frame it, or place
      any programs or texts for the purpose of removing any part of the services
      or indexing or erasing it or carrying out other data extraction operations
      from it, or loading the operation or functional work of any form of the
      services with unjustified loads or obstructing it or attempting to obtain
      the ability to access it without permission or working to harm the
      services or the systems or networks associated with it.
    </p>
    <p>
      <strong
        ><label class="heading"
          >(12): Change or amendment to the agreement:</label
        ></strong
      >
    </p>
    <p>
      12/1 The payer has the right, after notifying the beneficiary for a period
      of not less than 15 days, to amend, change, add, or delete the materials
      from this agreement at any time to another without obtaining the consent
      of the beneficiary, and the beneficiary&rsquo;s continued use of the
      services subject to this agreement shall be considered an implicit consent
      on his part to any change that occurs to this agreement.
    </p>
    <p>
      12/2 The Beneficiary shall not have the right to assign any rights or
      obligations stipulated in this Agreement without obtaining prior written
      consent from the Company.
    </p>
    <p>
      12/3 The payee has the right to assign this agreement to a third party
      without obtaining the consent of the beneficiary.
    </p>
    <p>
      <strong
        ><label class="heading"
          >(13): Effectiveness and termination of the agreement:</label
        ></strong
      >
    </p>
    <p>
      13/1 The provisions of this Agreement shall come into effect from the time
      the Beneficiary accesses the Madfoo Platform through any Madfoo Channel,
      and shall apply to any action taken by the Beneficiary while on the
      Platform.
    </p>
    <p>
      13/2 The Beneficiary may terminate this Agreement at any time, by ceasing
      to use the Madfoo Platform completely, however, any previous obligations
      owed by the Beneficiary shall remain subject to this Agreement.
    </p>
    <p>
      13/3 Madfo has the right to terminate Madfo&rsquo;s services and this
      agreement for any reason and at any time after notifying the beneficiary
      for a period of not less than 15 days. The beneficiary acknowledges and
      agrees to terminate this agreement with or without reason, without being
      obligated to inform him of the reason for terminating the agreement,
      without any obligation on Madfo&rsquo;s part towards the beneficiary.
    </p>
    <p>
      13/4 The beneficiary may request the closure of his paid account at any
      time by following the instructions on the website or application if there
      are no amounts owed to the company regarding the payment services from
      Madfoo, and the beneficiary remains responsible for all obligations
      related to his account even after the account is closed.
    </p>
    <p>
      <strong
        ><label class="heading"
          >(14): Correspondence and notification addresses:</label
        ></strong
      >
    </p>
    <p>
      14/1 When you create an account, you acknowledge and agree that you will
      communicate with us electronically, and therefore you agree to receive
      periodic messages from us and a paid team will communicate with you via
      email or may send you information via text messages, as well as posting
      notices on the website as part of the usual business management related to
      your use of the services, and you acknowledge and agree that your choice
      not to apply the aforementioned means of communication is something that
      will have an impact on your use of the services.
    </p>
    <p>
      14/2 All notices and other communications under this Agreement must be in
      writing via correspondence to the Company&rsquo;s email address shown on
      the Website.
    </p>
    <p>
      14/3 The beneficiary is obligated to notify us immediately of any change
      in his email address or any other contact information provided to Madfoo.
    </p>
    <p>
      <strong><label class="heading">(15): Applicable law:</label></strong>
    </p>
    <p>
      These terms, conditions and policies contained in this Agreement shall be
      governed by and construed in accordance with the laws of the Kingdom of
      Saudi Arabia.
    </p>
    <p>
      <strong><label class="heading">(16): Dispute Resolution:</label></strong>
    </p>
    <p>
      Any dispute, controversy or claim arising out of or relating to this
      Agreement, or the breach, termination or invalidity thereof, shall be
      settled by the competent court or judicial committee in the city of
      Riyadh, Kingdom of Saudi Arabia.
    </p>
    <p>
      <strong
        ><label class="heading">(17): The complete agreement:</label></strong
      >
    </p>
    <p>
      This Agreement, together with any amendments and any additional agreements
      relating to the use of the Platform and Services, constitutes the entire
      agreement between you and Madfoo.
    </p>
    <p>
      <strong><label class="heading">(18): Contact information:</label></strong>
    </p>
    <p>
      If you have any questions regarding this Agreement or the Paid Platform,
      please visit our Help page for our contact information.
    </p>
  </div>
</div>
