<header *ngIf="pageTitle !== 'email-confirmation'">
  <div class="container">
    <div class="header-container">
      <a [routerLink]="['/', translate.currentLang, 'home']" class="logo">
        <img
          loading="lazy"
          [src]="'assets/images/' + logoImageName"
          alt="Madfu"
        />
      </a>

      <button class="menu-toggle" id="menu-toggle" (click)="toggleMenu()">
        <img
          loading="lazy"
          [src]="
            pageTitle === 'Home'
              ? '/assets/images/menu-menu.svg'
              : '/assets/images/menu.svg'
          "
          alt="burger menu"
        />
      </button>

      <ul class="nav-menu" [ngClass]="{ active: menuOpen }">
        <li>
          <a
            [routerLink]="['/', translate.currentLang, 'about-us']"
            routerLinkActive="active"
          >
            {{ "about-us" | translate }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/', translate.currentLang, 'blogs']"
            routerLinkActive="active"
          >
            {{ "blogs-and-news" | translate }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/', translate.currentLang, 'be-partner']"
            routerLinkActive="active"
          >
            {{ "be-our-partner" | translate }}
          </a>
        </li>

        <li>
          <button
            class="open-contact-support-button"
            (click)="openContactSupportModal()"
          >
            {{ "have-issue-call-us" | translate }}!
          </button>
        </li>
      </ul>
    </div>
  </div>
</header>

<app-contact-support
  *ngIf="showContactSupportModal"
  (closeModal)="closeContactSupportModal()"
></app-contact-support>
