import { Component, OnInit } from '@angular/core';
import { ApplicationLanguages } from 'src/app/enums/lang';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  ApplicationLanguages = ApplicationLanguages;
  currentLang = localStorage.getItem('lang') || ApplicationLanguages.En;

  constructor() {}

  ngOnInit(): void {}
}
