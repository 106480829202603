<div class="join-us-widget">
  <div class="form">
    <h3>{{ "share-our-success-and-join-our-partners" | translate }}</h3>
    <p class="subtitle">
      {{ "ready-to-increase-your-sales-register-now" | translate }}
    </p>

    <p class="form-title">{{ "merchant-information" | translate }}</p>

    <form
      *ngIf="partnerForm"
      [formGroup]="partnerForm"
      (ngSubmit)="bePartner()"
    >
      <div class="form-widget">
        <div class="form-widget-content">
          <input
            type="text"
            class="form-widget-input"
            [placeholder]="'merchant-name' | translate"
            formControlName="merchantName"
            [ngClass]="{
            'invalid-input':
              controls?.['merchantName']?.invalid &&
              controls?.['merchantName']?.touched
          }"
          />
          <div class="error-space">
            <div
              *ngIf="
              controls?.['merchantName']?.invalid &&
              controls?.['merchantName']?.touched
            "
              class="error"
            >
              <span *ngIf="controls?.['merchantName']?.errors?.['required']">
                {{ "required" | translate }}
              </span>
              <span *ngIf="controls?.['merchantName']?.errors?.['pattern']">
                {{
                  "field-must-contains-only-letters-spaces-and-numbers"
                    | translate
                }}
              </span>
              <span *ngIf="controls?.['merchantName']?.errors?.['maxlength']">
                {{ "max-length-is-error" | translate : { length: 25 } }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-widget">
        <div class="form-widget-content">
          <input
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            type="tel"
            minlength="9"
            maxlength="9"
            class="form-widget-input"
            [placeholder]="'mobile-number' | translate"
            formControlName="phoneNumber"
            appLeadingZero
            [ngClass]="{
            'invalid-input':
              controls?.['phoneNumber']?.invalid &&
              controls?.['phoneNumber']?.touched
          }"
          />
          <div class="error-space">
            <div
              *ngIf="
              controls?.['phoneNumber']?.invalid &&
              controls?.['phoneNumber']?.touched
            "
              class="error"
            >
              <span *ngIf="(controls?.['phoneNumber']?.errors)?.['required']">
                {{ "required" | translate }}
              </span>

              <span *ngIf="(controls?.['phoneNumber']?.errors)?.['pattern']">
                {{ "phone-must-be-number" | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-widget">
        <div class="form-widget-content">
          <input
            id="email"
            type="text"
            [placeholder]="'mail' | translate"
            formControlName="email"
            [ngClass]="{
            'invalid-input':
              controls?.['email']?.invalid &&
              controls?.['email']?.touched
          }"
          />
          <div
            *ngIf="controls?.['email']?.touched && controls?.['email']?.errors"
            class="error"
          >
            <span *ngIf="controls?.['email']?.errors?.['required']">
              {{ "required" | translate }}
            </span>
            <p *ngIf="controls?.['email']?.errors?.['maxlength']">
              {{ "max-length-is-error" | translate : { length: 254 } }}
            </p>
            <p *ngIf="controls?.['email']?.errors?.['pattern']">
              {{ "email-error" | translate }}
            </p>
          </div>
        </div>
      </div>

      <button [disabled]="partnerForm.invalid" class="be-partner-button full">
        {{ "submit" | translate }}
        <img
          loading="lazy"
          src="assets/images/partner/baby-blue-send-square.png"
          alt="send square"
        />
      </button>
    </form>

    <button
      class="have-issue-call-us"
      type="button"
      (click)="openCallUsModal()"
    >
      {{ "have-issue-call-us" | translate }}!
    </button>
  </div>
</div>
