<div class="about-us-cover">
  <div class="container">
    <div class="about-us-cover-widget d-flex">
      <div class="about-us-titles">
        <h1>{{ "who-is-madfu" | translate }}</h1>
        <div class="buttons">
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/madfu-%D9%85%D8%AF%D9%81%D9%88%D8%B9/id1658723268"
            ><img
              loading="lazy"
              src="/assets/images/app-store-cover.svg"
              alt="app store"
          /></a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.sa.app.madfuser"
            ><img
              loading="lazy"
              src="/assets/images/about-us-google.svg"
              alt="about us google"
          /></a>
          <p>{{ "download-now" | translate }}</p>
        </div>
      </div>
      <div class="about-us-data">
        <div class="desc">
          <p>
            {{ "who-is-madfu-answer" | translate }}
          </p>

          <div class="imgs">
            <img
              loading="lazy"
              [src]="'/assets/images/about-us-check-' + currentLang + '.svg'"
              alt="check"
              class="imgs-img"
            />
            <div class="users">
              <img
                loading="lazy"
                src="/assets/images/about-us-check-2.svg"
                alt="check"
              />
              <p>
                <label>
                  <span class="plus">+</span><span>20</span>
                  <span class="thousand">{{ "million" | translate }}</span>
                </label>
                <span class="user">{{
                  "purchase-process-through-deferred-payment" | translate
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="our-values">
  <div class="container">
    <div class="our-values-widget">
      <h2>{{ "our-values" | translate }}</h2>
      <ul class="values">
        <li class="value-one">
          <img
            loading="lazy"
            src="/assets/images/values-one.svg"
            [alt]="'we-grow-together' | translate"
          />
          <h3>{{ "we-grow-together" | translate }}</h3>
          <p>{{ "we-help-you-grow-your-business" | translate }}</p>
        </li>
        <li class="value-two">
          <img
            loading="lazy"
            src="/assets/images/values-two.svg"
            [alt]="'why-dont-we-try?' | translate"
          />
          <h3>{{ "why-dont-we-try?" | translate }}</h3>
          <p>{{ "we-try-and-find-out-even-if-it-doesnt-work" | translate }}</p>
        </li>
        <li class="value-three">
          <img
            loading="lazy"
            src="/assets/images/values-three.svg"
            [alt]="'safe-side' | translate"
          />
          <h3>{{ "safe-side" | translate }}</h3>
          <p>{{ "dont-worry-about-doing-business-w-th-us" | translate }}</p>
        </li>
        <li class="value-four">
          <img
            loading="lazy"
            src="/assets/images/values-four.svg"
            [alt]="'your-friend-in-the-difficult-nights' | translate"
          />
          <h3>{{ "your-friend-in-the-difficult-nights" | translate }}</h3>
          <p>{{ "we-panic-and-help-with-whatever-we-can" | translate }}</p>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="our-main-partners-about-us">
  <div class="container">
    <div class="our-main-partners-container">
      <h2 class="title">{{ "our-partners" | translate }}</h2>
      <p class="sub-title">
        {{ "our-partners-desc" | translate }}
      </p>
      <div class="main-tabs">
        <div class="tab">
          <button
            class="tablinks"
            (click)="openItem($event, 'store')"
            id="defaultOpen"
          >
            {{ "store" | translate }}
          </button>
          <button class="tablinks" (click)="openItem($event, 'online')">
            {{ "online" | translate }}
          </button>
        </div>

        <!-- Tab content -->
        <div id="store" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of storePartners">
              <p>{{ currentLang == ApplicationLanguages.Ar ? store.titleAr : store.titleEn }}</p>
              <img
                loading="lazy"
                [src]="'/assets/images/store/' + store.image"
                [alt]="
                  currentLang == ApplicationLanguages.Ar
                    ? store.titleAr
                    : store.titleEn
                "
              />
              <a target="_blank" [href]="discoverLink">{{
                "explore" | translate
              }}</a>
            </li>
          </ul>
        </div>

        <div id="online" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of onlinePartners">
              <p>{{ currentLang == ApplicationLanguages.Ar ? store.titleAr : store.titleEn }}</p>
              <img
                loading="lazy"
                [src]="'/assets/images/online/' + store.image"
                [alt]="
                  currentLang == ApplicationLanguages.Ar
                    ? store.titleAr
                    : store.titleEn
                "
              />
              <a target="_blank" [href]="discoverLink">{{
                "explore" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
