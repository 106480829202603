import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() pageTitle: string = '';

  menuOpen: boolean = false;
  showContactSupportModal = false;

  constructor(private router: Router, protected translate: TranslateService) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuOpen = false;
      }
    });
  }

  get isHome() {
    return this.router.url.includes('/home');
  }

  get logoImageName() {
    return this.isHome ? 'madfu-logo-home.svg' : 'madfu-logo.svg';
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  openContactSupportModal() {
    this.showContactSupportModal = true;
    document.body.classList.add('no-scroll');
  }

  closeContactSupportModal() {
    this.showContactSupportModal = false;
  }
}
