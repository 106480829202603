<div class="cover-widget">
  <div class="container">
    <h1>{{ "PRIVACY_POLICY" | translate }}</h1>
  </div>
</div>

<div class="widget-data" *ngIf="currentLang == ApplicationLanguages.Ar">
  <div class="container">
    <p class="heading">
      <b>تاريخ السريان:&nbsp;<span>03/09/2023</span></b>
    </p>
    <p></p>
    <p>
      <strong
        ><label><span>التمهيد:&nbsp;</span></label></strong
      >
    </p>
    <p>
      <span
        >يرجى قراءة سياسة الخصوصية هذه بعناية قبل استخدام التطبيق أو الموقع
        الإلكتروني أو الاستفادة من أي من الخدمات المقدمة من قبلنا.</span
      >
    </p>
    <p>
      <span
        >بدايةً، نؤكد لكم أن شركة مدفوع المحدودة (&quot;المنصة&quot; أو
        &quot;مدفوع&quot;) تحترم خصوصيتكم، وتلتزم بحماية بياناتكم الشخصية وفقاً
        للأنظمة والتعليمات المعمول بها في المملكة العربية السعودية. ونهدف من
        سياسة الخصوصية هذه موافاتكم بالمعلومات حول السبل التي يتبعها مدفوع لجمع
        ومعالجة والكشف عن بياناتكم الشخصية، وتعتبر هذه السياسة جزء لا يتجزأ من
        الشروط والأحكام المتعلقة باستخدام منصة مدفوع.</span
      >
    </p>
    <p>
      <span
        >يجب ملاحظة أن منصتنا تشمل ما يلي: (١) مواقعنا الإلكترونية، بما في ذلك
        المواقع الفرعية ذات الصلة؛ (٢) تطبيقاتنا للجوال والجهاز اللوحي والأجهزة
        الذكية الأخرى؛ (٣) واجهات برنامج التطبيق؛ (٤) وأي أدوات، أو تقنيات، أو
        برامج أخرى حالية، أو مستقبلية - نشير فيها إلى الرجوع إلى سياسة الخصوصية
        هذه.</span
      >
    </p>
    <p>
      <span
        >يجب التنبه إلى أن سياسة الخصوصية هذه لا تشمل أو تعالج أو تنطبق على:
        سياسة الخصوصية الخاصة بالمتاجر أو الأطراف الثالثة، ولا ممارسات الخصوصية
        المنطبقة على تراخيصهم وامتيازاتهم، أو الخدمات التي نقدمها للمتاجر، أو
        على علاقتنا بمن ينوبوا عنا في تقديم بعض أو كل الخدمات من شركاء، أو
        الموظفين التابعين لنا.</span
      >
    </p>
    <p>
      <span
        >بمجرد وصولك إلى المنصة أو استخدامها، فإنك تقر وتوافق على أنك قد قرأت
        وفهمت ووافقت على جمع معلوماتك الشخصية وتخزينها واستخدامها والكشف عنها
        كما هو موضح في سياسة الخصوصية هذه وأحكام وشروط الاستخدام. كما أنك تقر
        بأن استمرار استخدامك لـمنصة مدفوع - بعد قيامنا بإشعاركم كتابياً بأي
        وسيلة عن التغييرات التي تطرأ على هذه السياسة أو على أحكام وشروط استخدام
        المنصة - سيعتبر بمثابة موافقة منك على هذه التغييرات.</span
      >
    </p>
    <p>
      <strong
        ><label
          ><span>١: البيانات التي نجمعها ولماذا نجمعها:</span></label
        ></strong
      >
    </p>
    <p>
      <span
        >١/١. البيانات التي قدمتها لنا مباشرة، أو عند استخدامك للمنصة، أو عندما
        تقوم بإنشاء حسابك في المنصة، أو استكمال ملف تعريف حسابك، أو التسجيل في
        القائمة البريدية، أو التواصل معنا:</span
      >
    </p>
    <p>
      <span>بياناتك الشخصية:</span
      ><span
        >&nbsp;ومنها (اسمك، وتاريخ الميلاد، والجنس، وبيانات التعريف بالهوية،
        وبيانات الحساب الخاص في النظام، وبيانات التواصل منها عنوان البريد
        الإلكتروني ورقم الهاتف وأي بيانات أخرى وغيرها مشابه)؛ ونستخدم هذه
        المعلومات الشخصية من أجل عدة أغراض منها على سبيل المثال لا الحصر: (١)
        إنشاء وتخصيص حسابك؛ (٢) التواصل معك مباشرةً بشأن الخدمات وكذلك بشأن أي
        أسئلة أو مشكلات أو شكاوى قد تكون لديك أو لدى مستخدمين آخرين، (٣) تنفيذ
        عمليات الشراء والدفع وتنفيذ الطلبات، و (٤) لغرض إرسال محتويات إعلامية
        وتسويقية إليك.</span
      >
    </p>
    <p>
      <span>معلوماتك المالية:</span
      ><span
        >&nbsp;عند التسجيل في منصة مدفوع أو تقديم طلب شراء يجب عليك تقديم
        معلومات الدفع مثل: (تفاصيل بطاقة البنك، أو البطاقة الائتمان، أو بطاقة
        الخصم) وغيرها من المعلومات. ونستخدم معلومات الدفع هذه لتنفيذ عمليات
        الشراء والدفع وتنفيذ الطلبات، وإتمام الالتزامات المالية عليك عن طريق
        الاستقطاع، ومكافحة الاحتيال ومنعه.</span
      >
    </p>
    <p>
      <span>معلومات العمليات:</span
      ><span
        >&nbsp;عندما ترسل طلب شراء أو دفع عن طريق منصة مدفوع، فإننا نقوم بجمع
        معلومات هذا الطلب بما في ذلك معلومات الأشياء التي اشتريتها، وتاريخ ووقت
        طلبك، والتعليمات الخاصة التي ضمنتها، والنقاط التي جمعتها أو المكافآت أو
        العروض الترويجية التي تستردها كجزء من برنامج الولاء أو المكافآت. ونستخدم
        معلومات العمليات هذه للمساعدة على إتمام طلبك، وتحديث نقاط المكافآت
        الخاصة بك وسجلك الائتماني، ومكافحة الاحتيال، وتخصيص تجربتك، وإجراء
        التحليلات التقنية والفنية، وتزويد فرق الإعلان والتسويق لدينا بهذه
        المعلومات.</span
      >
    </p>
    <p>
      <span>الاتصالات، والمشاركات، والمحتويات الأخرى:</span
      ><span
        >&nbsp;نقوم بحفظ جميع المعلومات الناتجة عن تفاعلاتك معنا أو مع شركائنا
        في المتاجر عبر منصة مدفوع، ونسجل أيضاً التعليقات والآراء التي تعبر عنها
        عند الرد على الاستطلاعات أو الدخول في سحوبات أو المشاركة في العروض
        الترويجية، ونستخدم هذه المعلومات أيضاً لمعالجة أسئلتك، ومشاكلك، ومخاوفك،
        وتقديم خدمات دعم العملاء ذات صلة، ونستخدمها أيضاً لأغراض حفظ الأمن
        والسلامة على منصة مدفوع، ولغرض تحسين خدماتنا عبر التحليلات التقنية
        والفنية. نحتفظ أيضًا بتقييماتك بخصوص المتاجر الموجودة على المنصة، والتي
        قد ننشرها لجميع مستخدمينا ليستفيدوا منها.</span
      >
    </p>
    <p>
      <span>المعلومات الأخرى التي تزودنا بها</span
      ><span
        >. كما أنه من الممكن أن تقوم بتزويدنا بمعلومات أخرى -حسب موافقتك أو رفضك
        لذلك-، فعلى سبيل المثال قد تمنحنا إمكانية الوصول إلى ألبوم صورك أو قائمة
        جهات الاتصال في جهازك المحمول أو حساب البريد الإلكتروني الخاص بك، والتي
        تمكنك من مشاركة المحتوى أو العروض الترويجية أو دعوة أصدقائك للتواصل معنا
        والاستفادة من خدماتنا.</span
      >
    </p>
    <p>
      <span
        >كما أنه من الممكن أن نستخدم المعلومات الشخصية بشكل مجمل للامتثال لأحكام
        النظام والأوامر القضائية، ولأغراض التحليل في مجالات الأعمال المختلفة
        وتحاليل السوق، والأبحاث، وتحديد الأنماط الديموغرافية، والتسويق والإعلان،
        وأغراض تجارية أخرى طالما أن النتيجة النهائية لا تحدد هويتك أو أي مستخدم
        آخر للخدمات.</span
      >
    </p>
    <p>
      <span
        >١/٢. المعلومات الشخصية التي يتم إنشاؤها /أو جمعها تلقائيًا أثناء
        استخدامك للمنصة:</span
      >
    </p>
    <p>
      <span>معلومات عن جهازك وبرامجه</span>. وقد تتضمن المعلومات التي نجمعها
      معلومات متعلقة بجهازك وبرامجه، وتتضمن عنوان ال(<span>IP</span
      ><span
        >) الخاص بك، ونوع المتصفح، ومزود خدمة الإنترنت، ونوع الجهاز، وطرازه،
        والشركة المصنعة له، ونظام التشغيل، والتاريخ والوقت، والمعرف الفريد
        (</span
      ><span>unique ID</span
      ><span
        >) الذي يتيح لنا تحديد نوع متصفحك، أو جهازك المحمول، أو حسابك (بما في
        ذلك، على سبيل المثال لا الحصر: معرّف الجهاز الدائم الخاص بالإعلانات)،
        ومعلومات أخرى مماثلة. وقد نعمل أيضًا مع شركاء من جهات خارجية لتوظيف
        التقنيات، بما في ذلك أدوات النمذجة الإحصائية، التي تسمح لنا بالتعرف عليك
        والاتصال بك عبر أجهزة متعددة. والغرض من ذلك هو أن نستخدم معلومات الجهاز
        والبرنامج لتشخيص المشاكل التقنية وإصلاحها، وتقديم إعلانات مخصصة لك،
        وتخصيص تجربتك، وإجراء التحليلات الفنية والتقنية، وتحسين منتجاتنا
        وخدماتنا.</span
      >
    </p>
    <p>
      <span>معلومات حول طريقة وصولك إلى خدماتنا واستخدامها.</span
      ><span
        >&nbsp;وتتضمن تلك المعلومات الموقع الالكتروني الذي أتيت منه والموقع الذي
        ستنتقل إليه بعد ذلك. بالإضافة إلى أننا نتتبع الصفحات التي تزورها على
        المنصة، والروابط التي تنقر عليها على المنصة، سواء قمت بفتح رسائل بريد
        إلكتروني أو نقرت على الروابط الموجودة في رسائل البريد الإلكتروني التي
        نرسلها، والإجراءات الأخرى التي تتخذها في المنصة. وقد نستخدم هذه
        المعلومات لتحديد المنتجات والخدمات التي قد تهمك، ولتحسين منتجاتنا
        وخدماتنا، ولتقييم نجاح حملاتنا الإعلانية والتسويقية، ولإرسال الدعايات
        التسويقية.</span
      >
    </p>
    <p>
      <span>المعلومات التحليلية.&nbsp;</span>قد نقوم بجمع البيانات التحليلية أو
      استخدام أدوات التحليلات الخاصة بطرف ثالث مثل (<span>Google Analytics</span
      ><span
        >) لمساعدتنا في قياس مقدار الحركة وطريقة الاستخدام والتركيبة السكانية
        لمستخدمي المنصة من أجل تحسين خدماتنا. يمكنك معرفة المزيد حول ممارسات
        (</span
      ><span>Google</span><span>) على&nbsp;</span
      ><span>http://www.google.com/policies/privacy/partners</span
      ><span>.</span>
    </p>
    <p>
      <span>معلومات الموقع الجيوغرافي.</span
      ><span
        >&nbsp;في بعض الحالات، من الممكن أن تقدم لنا كمستخدم للمنصة معلومات
        موقعك الجيوغرافي بشكل مباشر (على سبيل المثال عندما تزود التاجر بعنوان
        تسليم السلعة)، وفي حالات أخرى، قد يتم رصد موقعك من خلال ال(</span
      ><span>GPS</span
      ><span
        >) أو شبكة الواي فاي، أو عن طريق أبراج الجوال، أو قد نستنتج موقعك بناءً
        على المعلومات الأخرى التي نجمعها. (على سبيل المثال: يمكننا تقريب موقعك
        من خلال عنوان الـ(</span
      ><span>IP</span
      ><span
        >) الخاص بك.) ونستخدم هذه المعلومات لتخصيص المحتوى على خدماتنا (على سبيل
        المثال: نعرض لك المتاجر القريبة منك)، وكذلك قد نستخدمها لتسهيل عملية
        الطلب والتسليم، ومكافحة الاحتيال، وقياس كمية الحركة والتحليلات الفنية
        والتقنية على الخدمات.</span
      >
    </p>
    <p>
      <span
        >كما يجب التنبيه إلى أننا نستخدم أيضًا واجهة برمجة تطبيقات خرائط (</span
      ><span>Google</span><span>) لجمع معلومات حول موقعك. وتستخدم (</span
      ><span>Google</span
      ><span>) تقنيات مختلفة لتحديد موقعك، بما في ذلك عنوان الـ(</span
      ><span>IP</span><span>) الخاص بك، ونظام تحديد المواقع العالمي (</span
      ><span>GPS</span><span>) وأجهزة الاستشعار الأخرى التي قد تزود (</span
      ><span>Google</span
      ><span
        >) بمعلومات عن الأجهزة المجاورة ونقاط الواي فاي وأبراج الجوال؛ (الرجاء
        الاطلاع على سياسة خصوصية (</span
      ><span>Google</span><span>) وشروط خدمة (خرائط&nbsp;</span
      ><span>Google</span><span>) لمعرفة المزيد).</span>
    </p>
    <p>
      <span>١/٣. البيانات التي نجمعها من مصادر أخرى:</span>
    </p>
    <p>
      <span>المعلومات التي نتلقاها من مواقع جهات أخرى تتصل بها خدماتنا</span
      ><span
        >. كما أننا قد نتلقى معلومات شخصية عنك من أطراف ثالثة وندمجها مع
        المعلومات التي نجمعها من خلال منصتنا. على سبيل المثال، قد نحصل على
        معلومات عند تسجيل الدخول من خلال شبكة اجتماعية أو خدمات المصادقة التابعة
        لجهات خارجية، مثل (</span
      ><span>Facebook</span><span>) أو (</span><span>Google</span
      ><span
        >). تصادق هذه الخدمات على هويتك وتوفر لك خيار مشاركة معلومات شخصية معينة
        معنا، والتي قد تتضمن اسمك وعنوان بريدك الإلكتروني ودفتر العناوين وجهات
        الاتصال أو معلومات أخرى. وبالمثل، عندما تتفاعل معنا من خلال موقع وسائل
        اجتماعية أو خدمة جهة خارجية، مثل عندما تسجل إعجابك أو تتابع أو تشارك
        محتوى مدفوع على (</span
      ><span>Facebook</span><span>) أو (</span>Twitter<span
        >) أو مواقع أخرى، فقد نتلقى معلومات تسمح شبكة التواصل الاجتماعي
        بمشاركتها مع أطراف ثالثة. تعتمد البيانات التي نتلقاها من مواقع الجهات
        الخارجية هذه على سياسات الطرف الثالث وإعدادات الخصوصية الخاصة بك على
        موقع الطرف الثالث، ويجب عليك مراجعة تلك الاتفاقيات والسياسات قبل
        استخدامها.</span
      >
    </p>
    <p>
      <span>&nbsp;</span>
    </p>
    <p>
      <strong
        ><label
          ><span>٢: استخدام ومعالجة البيانات الشخصية:&nbsp;</span></label
        ></strong
      >
    </p>
    <p>
      <span
        >بالإضافة إلى الاستخدامات الموضحة أعلاه، سنستخدم بياناتكم الشخصية حسب
        الأنظمة والتعليمات المرعية وسياسية الخصوصية هذه، وذلك في عدة أغراض ومنها
        ما يلي:</span
      >
    </p>
    <p>
      <span>٢/١. توفير المنصة والخدمات وصيانتها وتحسينها وتطويرها:</span>
    </p>
    <p>
      <span>ومنها على سبيل المثال:&nbsp;</span>
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span>
      <span
        >تمكينك من إنشاء حسابات أو الوصول إلى المنصة والخدمات واستخدامها (على
        سبيل المثال لا الحصر: حفظ المعلومات حتى لا تضطر إلى إعادة إدخالها أثناء
        زيارتك أو في المرة التالية التي تزور فيها المنصة).</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >تلبية طلباتك ومعالجة المدفوعات (على سبيل المثال لا الحصر: السماح لك
        بإتمام عملية الشراء من المتاجر وتسليمه لك، أو تزويدك بالمعلومات التي
        تطلبها)&nbsp;</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >تقديم محتوى ومعلومات مخصصة وشخصية لتحسين تجربتك في منصة مدفوع وتحسينها،
        وتزويدك بالمميزات والتحسينات على الخدمة.</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >إجراء التحليلات الفنية والتقنية والتسويقية، واكتشاف نمط الاستخدام على
        منصتنا وخدماتنا.</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >اختبار وتطوير ميزات ووظائف جديدة وتحسين النظام الأساسي والخدمات.</span
      >
    </p>
    <p>
      <span>٢/٢. الحفاظ على سلامة واستقرار وأمن منصتنا وخدماتنا:&nbsp;</span
      ><span
        >قد نستخدم المعلومات الشخصية للحفاظ على سلامة وأمن منصتنا وخدماتنا؛
        ومنها على سبيل المثال لا الحصر:</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >اكتشاف، أو منع عمليات الاحتيال، أو إساءة الاستخدام، أو الأنشطة الضارة
        الأخرى.</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span>تشخيص المشكلات التقنية أو إصلاحها.</span>
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >إجراء التحقيقات الأمنية وتقييمات المخاطر، وتحسين وفرض إجراءاتنا
        الوقائية.</span
      >
    </p>
    <p>
      <span>٢/٣. التواصل معك لتقديم الدعم والرسائل المهمة الأخرى:</span
      ><span
        >&nbsp;قد نستخدم المعلومات الشخصية لتوصيل رسائل مهمة إليك أو لتزويدك
        بالدعم. فقد نرسل إليك رسائل بريد إلكتروني أو رسائل متعلقة بالخدمة على
        سبيل المثال (التحقق من حساب المستخدم، والتغييرات أو التحديثات على ميزات
        المنصة أو الخدمات، والإشعارات الفنية والأمنية). مع العلم أن مدفوع ليست
        مسؤولة عن الرسائل المتأخرة أو التي لم يتم إيصالها.</span
      >
    </p>
    <p>
      <span>٢/٤. توفير إعلاناتنا التسويقية وتخصيصها وقياسها وتحسينها:</span
      ><span
        >&nbsp;قد نستخدم المعلومات الشخصية لتوفير إعلاناتنا التسويقية وتخصيصها
        وقياسها وتحسينها، ومنها على سبيل المثال:</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >إدارة المكافآت، أو الاستطلاعات، أو المسابقات، أو الأنشطة الترويجية، أو
        الأحداث الأخرى التي ترعاها أو تديرها مدفوع أو شركائها الخارجيين.</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >إبلاغك بالمنتجات والبرامج والخدمات والعروض الترويجية التي نعتقد أنها قد
        تهمك، من خلال رسائل البريد الإلكتروني والإشعارات الفورية ووسائل الاتصال
        الأخرى.</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >السماح لك بإحالتنا إلى أصدقائك حتى نتمكن من إرسال عرض ترويجي لهم من
        خلال المنصة. إذا كنت ترغب في استخدام هذه الميزة، فقد تتم مطالبتك
        بتزويدنا، وقد نستخدم، اسم صديقك وعنوان بريده الإلكتروني والمعلومات
        الأخرى التي تشاركها معنا. باستخدام هذه الوظيفة، فإنك تؤكد أنه يحق لنا
        استخدام اسم صديقك وعنوان بريده الإلكتروني لهذا الغرض. سنستخدم هذه
        المعلومات لإرسال بريد إلكتروني لدعوة الصديق لزيارة المنصة ولأي أغراض
        أخرى يتم الكشف عنها وقت مشاركتك في البرنامج.</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >تحديد فعالية خدماتنا وحملاتنا الترويجية، والتعرف عليك والاتصال بك عبر
        أجهزة متعددة.</span
      >
    </p>
    <p>
      <span>٢/٥. الالتزام بالأوامر والإجراءات القانونية والتنظيمية:</span
      ><span
        >&nbsp;قد نستخدم المعلومات الشخصية فيما يتعلق بالإجراءات والمتطلبات
        القانونية والتنظيمية، على سبيل المثال (قد نحتاج إلى المعلومات الشخصية
        بغرض تنفيذ الالتزامات المفروضة علينا كشركة من تقارير وغيرها، أو عند
        تلقينا لأمر قضائي أو استدعاء حكومي بطلب الإفصاح عن البيانات، أو وجود
        مصلحة وفقاً لتقديرنا الخاص أن الكشف عن البيانات الشخصية ضروري وفقاً لما
        تقضي به الأنظمة والتعليمات).</span
      >
    </p>
    <p>
      <span>٢/٦. المحافظة على حقوقنا:&nbsp;</span
      ><span
        >عند وصولك إلى منصتنا أو خدماتنا أو استخدامها، فإنك ملزم بشروط وأحكام
        الاستخدام وسياسة الخصوصية هذه، لضمان امتثالك لها قد نستخدم معلوماتك
        الشخصية لرصد انتهاكات شروط الاستخدام الخاصة بنا والتحقيق فيها أو منعها
        أو التخفيف منها بالإضافة إلى إنفاذ اتفاقياتنا مع الأطراف الثالثة وشركاء
        الأعمال.</span
      >
    </p>
    <p>
      <strong
        ><label
          ><span>٣: مشاركتنا وإفصاحنا عن المعلومات الشخصية:</span></label
        ></strong
      >
    </p>
    <p>
      <span
        >يجب التنبه إلى أننا نقوم بالكشف عن معلوماتك الشخصية لصالح الأطراف
        الأخرى الآتية:</span
      >
    </p>
    <p>
      <span>٣/١. الباعة لدى مدفوع:</span
      ><span
        >&nbsp;قد نكشف عن معلوماتك الشخصية للبائعين اللذين تشتري منهم، والأطراف
        الثالثة المرتبطين بهؤلاء البائعين مثل (موفري نقاط البيع أو موظفي التوصيل
        الخاصين بهم)، حسب الحاجة لتلبية طلباتك، بما في ذلك التواصل معك بشأن حالة
        طلبك أو العناصر الملغاة، وإذا أجريت عملية شراء من بائع يقدم برامج ولاء
        أو مكافآت من خلال منصتنا، فقد نكشف معلوماتك الشخصية للمورد حسب الحاجة
        لتسهيل مشاركتك في برامج الولاء أو المكافآت الخاصة بالبائع، بما في ذلك
        تتبع النقاط وعمليات الاسترداد.</span
      >
    </p>
    <p>
      <span>٣/٢. مقدمو الخدمات وشركاؤنا:</span
      ><span
        >&nbsp;قد نقوم بكشف المعلومات الشخصية لأطراف ثالثة تقدم خدمات لنا أو
        نيابة عنا، مثل استضافة مواقع الويب، وتحليل البيانات، وتوفير البنية
        التحتية وخدمات تكنولوجيا المعلومات، وخدمة العملاء، وخدمات تسليم البريد
        الإلكتروني، واكتشاف الاحتيال، ومراقبة الأمان، والإعلان والتسويق، وغير
        ذلك من خدمات مماثلة.</span
      >
    </p>
    <p>
      <span>٣/٣. مزودي خدمات التحليلات:</span
      ><span
        >&nbsp;قد نشارك المعلومات الشخصية لك مع مزودي خدمات التحليلات لفهم
        التركيبة الديموغرافية لمستخدمينا وزوارنا بشكل أفضل، ولتخصيص منصتنا،
        وخدماتنا، وتحسينها.</span
      >
    </p>
    <p>
      <span>٣/٤. مزودي خدمات الإعلان والتسويق والترويج:</span
      ><span
        >&nbsp;قد نشارك المعلومات الشخصية مع أطراف ثالثة فيما يتعلق بأنشطة
        التسويق المشترك، وتطوير حملات تسويقية مخصصة وموجهة، لإطلاق العروض
        الترويجية ولجهود إعلانية أخرى مماثلة. علي سبيل المثال:&nbsp;</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span>الجهات الإعلانية:</span
      ><span
        >&nbsp;قد نكشف عن المعلومات الشخصية للجهات الإعلانية الراعية للعروض
        الترويجية لتقديم عروض أو أنشطة ترويجية مشتركة أو خدمات ذات علامة تجارية
        مشتركة، وهذا الكشف ضروري لتلبية طلبك. لهذا يجب عليك مراجعة القواعد وشروط
        كل عرض ترويجي تشارك فيه، حيث قد يحتوي العرض الترويجي على معلومات مهمة
        إضافية حول استخدامنا لمعلوماتك الشخصية، أو عن استخدام الجهة الإعلانية
        إلى الحد الذي تتعارض فيه قواعد أو شروط تلك العروض الترويجية المتعلقة
        بمعالجة معلوماتك الشخصية مع سياسة الخصوصية هذه، وسيعمل بقواعد تلك العروض
        الترويجية.</span
      >
    </p>
    <p>
      <span class="light-text">&middot;&nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span>الشبكات الاجتماعية:</span> قد نقوم بالكشف عن المعلومات الشخصية، مثل
      المعرف أو عنوان البريد الإلكتروني أو المعلومات التي يتم جمعها تلقائيًا من
      خلال تقنيات التتبع، إلى الشبكات الاجتماعية بغرض عرض الإعلانات والمحتوى
      المخصص على تلك الشبكات الاجتماعية.
    </p>
    <p>
      <span>٣/٥. البائعين والعلامات التجارية:&nbsp;</span
      ><span
        >قد نكشف عن المعلومات الشخصية لمجموعة مختارة من البائعين والعلامات
        التجارية التي اشتريت منها سابقاً من خلال المنصة وكذلك الشركات التابعة
        لها ومقدمي الخدمات من الأطراف الثالثة. قد يستخدم هؤلاء البائعون
        والعلامات التجارية والشركات التابعة لهم معلوماتك على النحو المبين في
        سياسات الخصوصية الخاصة بهم، والتي قد تشمل إرسال رسائل تسويقية ومحتوى
        ترويجي آخر إليك.</span
      >
    </p>
    <p>
      <span>٣/٦. الخدمات والمنصات العامة:</span
      ><span
        >&nbsp;نوفر من خلال الخدمات بعض الميزات التي قد تمكنك من نشر المعلومات
        والمواد، على سبيل المثال، التقييمات والمراجعات، وتحميل الصور، وخدمة
        الدردشة؛ يرجى ملاحظة أن أي معلومات تقدمها فيما يتعلق بهذه الخدمات قد
        تكون متاحة لزوار الموقع وعامة الناس. يرجى عدم الكشف عن معلوماتك الشخصية
        في هذه المناطق العامة أو التفاعلية على الخدمات، ونحثك على ممارسة السرية
        والحذر عند اتخاذ قرار بالكشف عن أي معلومات أو مواد أخرى في هذه المناطق
        العامة أو التفاعلية على الخدمات.</span
      >
    </p>
    <p>
      <span>٣/٧. الحقوق والالتزامات القانونية:</span
      ><span
        >&nbsp;قد نكشف عن المعلومات الشخصية لأطراف ثالثة، مثل المستشارين
        القانونيين، وفقًا لما يقتضي النظام أو قواعد مكافحة الاحتيال أو تقليل
        أخطار الائتمان وتحصيل الديون المستحقة لنا، أو ممارسة، أو حماية الحقوق،
        أو الممتلكات، أو السلامة الشخصية لـ مدفوع أو زوارنا أو غيرهم.</span
      >
    </p>
    <p>
      <span>٣/٨: عند وجود الموافقة منك:&nbsp;</span
      ><span>قد نكشف عن معلومات شخصية عنك لأطراف ثالثة أخرى بموافقتك.</span>
    </p>
    <p>
      <strong
        ><label><span>٤: كيف نخزن بياناتك ونحميها:</span></label></strong
      >
    </p>
    <p>
      <span>تخزين ونقل البيانات:&nbsp;</span
      ><span
        >يتم تخزين المعلومات الخاصة بك التي يتم جمعها من خلال خدمتنا ومعالجتها
        في المملكة العربية السعودية. إذا قمت بإنشاء حساب على المنصة كمقيم من
        خارج المملكة العربية السعودية، فإنك بمجرد استخدام المنصة أو الخدمات،
        فإنك توافق على سياسة الخصوصية هذه وتوافق على جمع ونقل وتخزين ومعالجة
        المعلومات إلى المملكة العربية السعودية.</span
      >
    </p>
    <p>
      <span>الحفاظ على البيانات:</span
      ><span
        >&nbsp;سنسعى للمحافظة على المعلومات الخاصة بك بطريقة آمنة، ونحن نهتم
        بأمان معلوماتك ونستخدم الضمانات المادية والإدارية والتكنولوجية المصممة
        للحفاظ على سلامة وأمن جميع المعلومات التي يتم جمعها من خلال خدمتنا. يرجى
        التنبه إلى أن حماية معلوماتك الشخصية هي أيضًا مسؤوليتك، ولذلك، نوصي بشدة
        بتغيير كلمات المرور الخاصة بك بشكل دوري، واستخدام مجموعة من الأحرف
        والأرقام والرموز الخاصة، والتأكد من استخدام متصفح آمن، واتخاذ الاحتياطات
        عند مشاركة معلوماتك الشخصية على الإنترنت. إذا علمت أو كان لديك سبب
        للاعتقاد بأن بيانات حسابك قد ضاعت أو سُرقت أو تم الاستيلاء عليها أو تم
        اختراقها بطريقة غير نظامية أو في حالة أي استخدام غير مصرح به فعليًا أو
        مشتبه به، يرجى الاتصال بنا باتباع الإرشادات الموجودة في قسم الاتصال بنا
        أدناه.</span
      >
    </p>
    <p>
      <strong
        ><label><span>٥: حقوقك على بياناتك الشخصية:</span></label></strong
      >
    </p>
    <p>
      <span>تحديث معلوماتك:</span
      ><span
        >&nbsp;يمكنك تحديث معلوماتك عن طريق اتباع التعليمات الموجودة على صفحة
        حسابك في مدفوع.</span
      >
    </p>
    <p>
      <span>التحكم في الوصول إلى معلومات جهازك والإشعارات الفورية:</span
      ><span
        >&nbsp;يمكنك التحكم في الوصول إلى معلومات جهازك أو إلغاء الاشتراك في
        الإشعارات الفورية من خلال تطبيق &quot;الإعدادات على جهازك&quot;، على
        سبيل المثال: يمكنك سحب إذن التطبيق للوصول إلى بيانات موقعك من خلال
        إعدادات جهازك، على الرغم من أننا قد نستمر في تقريب موقعك بناءً على عنوان
        الـ(</span
      ><span>IP</span><span>) الخاص بك أو من خلال وسائل أخرى.</span>
    </p>
    <p>
      <span>إعدادات الاتصال:</span
      ><span
        >&nbsp;يمكنك أيضاً إيقاف تلقي الاتصالات الترويجية عبر البريد الإلكتروني
        من خلال إعدادات الاتصال في حساب مدفوع، إلا أنه لا يمكن إلغاء الاشتراك في
        الاتصالات المتعلقة بالخدمة على سبيل المثال (التحقق من الحساب والهوية،
        والاتصالات المتعلقة بالمشتريات، والتغييرات والتحديثات على ميزات الخدمات،
        والإشعارات القانونية، والإشعارات الفنية والأمنية).</span
      >
    </p>
    <p>
      <span>حذف بياناتك:</span
      ><span
        >&nbsp;في جميع الأحوال يحق لك أن تطلب حذف معلوماتك التي نمتلكها في أي
        وقت عن طريق إرسال طلبك إلى أي عناوينا المدرجة في هذه الاتفاقية. ومع ذلك،
        يرجى ملاحظة أننا قد لا نتمكن من تلبية مثل هذا الطلب في بعض الحالات التي
        ينص عليها النظام أو في حالة وجود مطالبات مالية عليك.</span
      >
    </p>
    <p>
      <strong
        ><label><span>٦: مدة حفظ البيانات الشخصية:</span></label></strong
      >
    </p>
    <p>
      <span
        >المدة التي نحتفظ بها بالبيانات الشخصية هي المدة التي تلزمنا للوفاء
        بالأغراض التي قمنا بجمع البيانات لأجلها، ومنها الأغراض المتعلقة باستيفاء
        أية اشتراطات قانونية، أو تنظيمية، أو ضريبية، أو مالية وغيرها مما يتعلق
        بهذا الخصوص، ويمكن أن تطول هذه المدة في حال وجود إجراءات قضائية أو يحتمل
        وجودها، وفي حال عدم وجود دواعي أو مقتضيات لحفظها فإننا نقوم حينئذٍ
        بإلغائها.</span
      >
    </p>
    <p>
      <strong
        ><label><span>٧: كيف نقوم بالتخلص من بياناتك:</span></label></strong
      >
    </p>
    <p>
      <span
        >إذا لم يعد من الضروري القيام بحفظ البيانات الشخصية، فإننا نقوم حينئذ
        بحذف بياناتك من قواعد البيانات الخاصة بنا.&nbsp;</span
      >
    </p>
    <p>
      <strong
        ><label><span>٨: خصوصية الأطفال:</span></label></strong
      >
    </p>
    <p>
      <span
        >خدماتنا وخدمات منصتنا غير معنية لاستخدام أي شخص يقل عمره عن الثامنة عشر
        (١٨). ونؤكد على أننا لا نجمع أي معلومات تعريف شخصية من الأطفال دون سن
        الثامنة عشر (١٨) بالقصد، وفي حالة اكتشافنا أن طفلاً يقل عمره عن (١٨) عام
        قام بتزويدنا بمعلومات شخصية، فإننا نحذفها على الفور من خوادمنا. إذا كنت
        والداً أو وصياً وتعلم أن طفلك قد زودنا بمعلومات شخصية فيرجى الاتصال بنا
        حتى نتمكن من القيام بالإجراءات اللازمة.</span
      >
    </p>
    <p>
      <strong
        ><label><span>٩: مواقع الأطراف الأخرى:</span></label></strong
      >
    </p>
    <p>
      <span
        >قد تتضمن مواقعنا الإلكترونية وخدماتنا روابط إلى مواقع إلكترونية ومكونات
        إضافية وتطبيقات لأطراف أخرى. باستثناء ما ننشره أو نعتمده صراحةً أو نشير
        إلى سياسة الخصوصية هذه فيه، فإن سياسة الخصوصية هذه لا تنطبق على تلك
        المواقع الإلكترونية، ولسنا مسؤولين عن أي ممارسات خاصة بالمعلومات الشخصية
        للمواقع الإلكترونية الخاصة بالأطراف الثالثة وخدماتهم أو ممارساتهم.
        وللتعرف على ممارسات المعلومات الشخصية للأطراف الثالثة، يرجى مراجعة سياسة
        الخصوصية الخاصة بهم.</span
      >
    </p>
    <p>
      <strong
        ><label
          ><span>١٠: التغيرات التي تطرأ على هذه السياسة:</span></label
        ></strong
      >
    </p>
    <p>
      <span
        >سيتم نشر أي تغييرات نجريها على سياسة الخصوصية الخاصة بنا في المستقبل
        على هذه الصفحة، وعندما نجري تغييرات على سياسة الخصوصية هذه، سنقوم بتغيير
        تاريخ &quot;السريان&quot; في بداية سياسة الخصوصية هذه. إذا قمنا بإجراء
        تغييرات جوهرية على سياسة الخصوصية هذه، فسنخطرك عبر البريد الإلكتروني على
        عنوان بريدك الإلكتروني المسجل، أو عن طريق النشر البارز على المنصة، أو من
        خلال قنوات الاتصال المناسبة الأخرى. تكون جميع التغييرات سارية المفعول من
        تاريخ النشر ما لم ينص على خلاف ذلك. إذا لم تقم بإلغاء حسابك قبل تاريخ
        سريان سياسة الخصوصية المحدثة، فسيخضع وصولك المستمر إلى المنصة أو الخدمات
        أو استخدامها إلى سياسة الخصوصية المحدثة.</span
      >
    </p>
    <p>
      <strong
        ><label><span>١١: اتصل بنا:&nbsp;</span></label></strong
      >
    </p>
    <p>
      <span
        >إذا كان لديك أي أسئلة أو اقتراحات حول سياسة الخصوصية الخاصة بنا، فلا
        تترد في الاتصال بنا عبر البريد الإلكتروني&nbsp;</span
      ><span>contact@madfu.com.sa</span><span>&nbsp;</span>
    </p>
    <p>
      <span>&nbsp;</span>
    </p>
    <p>
      <span
        >* يرجى ملاحظة أن اتصالات البريد الإلكتروني ليست دائماً آمنة؛ وبالتالي،
        يُرجى عدم تضمين معلومات بطاقة الائتمان و / أو غيرها من المعلومات الشخصية
        الحساسة في مراسلاتك عبر البريد الإلكتروني معنا.</span
      >
    </p>
    <p>&nbsp;</p>
  </div>
</div>

<div class="widget-data" *ngIf="currentLang == ApplicationLanguages.En">
  <div class="container">
    <p class="heading">
      <strong>Effective Date: &nbsp;<span>09/03/2023</span></strong>
    </p>
    <p>
      <br />
    </p>
    <p>
      <strong
        ><strong
          ><label><span>Introduction:&nbsp;</span></label></strong
        ></strong
      >
    </p>
    <p>
      <span
        >Please read this Privacy Policy carefully before using the Application
        or Website or availing any of the Services provided by us.</span
      >
    </p>
    <p>
      <span
        >First, we assure you that Madfoo Limited (&ldquo;Platform&rdquo; or
        &ldquo;Madfoo&rdquo;) respects your privacy and is committed to
        protecting your personal data in accordance with the applicable laws and
        regulations in the Kingdom of Saudi Arabia. The aim of this privacy
        policy is to provide you with information about the methods Madfoo uses
        to collect, process and disclose your personal data, and this policy is
        considered an integral part of the terms and conditions related to the
        use of the Madfoo platform.</span
      >
    </p>
    <p>
      <span
        >Please note that our Platform includes: (1) our websites, including
        related sub-sites; (2) our mobile, tablet and other smart device
        applications; (3) our application program interfaces; and (4) any
        current or future tools, technologies or other software &ndash; which we
        refer to by reference in this Privacy Policy.</span
      >
    </p>
    <p>
      <span
        >Please note that this Privacy Policy does not cover, address, or apply
        to: the privacy policies of stores or third parties, the privacy
        practices applicable to their licenses and privileges, the services we
        provide to stores, or our relationship with partners or employees who
        provide some or all of the services on our behalf.</span
      >
    </p>
    <p>
      <span
        >By accessing or using the Platform, you acknowledge and agree that you
        have read, understood and consented to the collection, storage, use and
        disclosure of your personal information as described in this Privacy
        Policy and the Terms and Conditions of Use. You also acknowledge that
        your continued use of the Madfoo Platform - after we have notified you
        in writing by any means of changes to this Policy or the Terms and
        Conditions of Use of the Platform - will be deemed your acceptance of
        such changes.</span
      >
    </p>
    <p>
      <strong
        ><strong
          ><label
            ><span>1: The data we collect and why we collect it:</span></label
          ></strong
        ></strong
      >
    </p>
    <p>
      <span
        >1/1. Data you provide to us directly, or when you use the platform, or
        when you create your account on the platform, or complete your account
        profile, or register for the mailing list, or communicate with us:</span
      >
    </p>
    <p>
      <span>Your personal data:&nbsp;</span
      ><span
        >&nbsp;including (your name, date of birth, gender, identification data,
        private account data in the system, and contact data including email
        address, phone number and any other data and similar); We use this
        personal information for several purposes, including but not limited to:
        (1) creating and customizing your account; (2) communicating with you
        directly about the services as well as about any questions, problems or
        complaints that you or other users may have, (3) carrying out purchases
        and payments and fulfilling requests, and (4) for the purpose of sending
        you informational and marketing content.</span
      >
    </p>
    <p>
      <span>Your financial information:&nbsp;</span
      ><span
        >&nbsp;When registering on the Madfoo platform or placing a purchase
        order, you must provide payment information such as: (bank card details,
        credit card, or debit card) and other information. We use this payment
        information to carry out purchases and payments, fulfill orders,
        complete financial obligations to you by deduction, and combat and
        prevent fraud.</span
      >
    </p>
    <p>
      <span>Transaction Information:&nbsp;</span
      ><span
        >&nbsp;When you submit a purchase order or payment through the Paid
        Platform, we collect information about that order including information
        about the items you purchased, the date and time of your order, any
        special instructions you included, and any points you have earned or
        rewards or promotions you redeem as part of a loyalty or rewards
        program. We use this transaction information to help complete your
        order, update your rewards points and credit history, combat fraud,
        personalize your experience, perform technical and operational analyses,
        and provide this information to our advertising and marketing
        teams.</span
      >
    </p>
    <p>
      <span>Communications, Posts, and Other Content:&nbsp;</span
      ><span
        >&nbsp;We store all information resulting from your interactions with us
        or our partners in stores through the Madfoo platform, and we also
        record the comments and opinions you express when responding to surveys
        or entering sweepstakes or participating in promotions, and we also use
        this information to address your questions, problems, and concerns, and
        to provide relevant customer support services, and we also use it for
        the purposes of maintaining security and safety on the Madfoo platform,
        and for the purpose of improving our services through technical and
        technical analyses. We also keep your ratings regarding the stores on
        the platform, which we may publish for all our users to benefit
        from.</span
      >
    </p>
    <p>
      <span>Other information you provide to us&nbsp;</span
      ><span
        >. You may also provide us with other information - based on your
        consent or refusal - for example, you may give us access to your photo
        album or contact list on your mobile device or your email account, which
        enables you to share content or promotions or invite your friends to
        contact us and benefit from our services.</span
      >
    </p>
    <p>
      <span
        >We may also use personal information in aggregate form to comply with
        regulatory requirements and court orders, for business analysis, market
        analysis, research, demographic profiling, marketing and advertising,
        and other business purposes as long as the end result does not identify
        you or any other user of the Services.</span
      >
    </p>
    <p>
      <span
        >1/2. Personal information that is automatically generated/collected
        during your use of the Platform:</span
      >
    </p>
    <p>
      <span>Information about your device and software</span>
      . The information we collect may include information about your device and
      software, including
      <span>your IP&nbsp;</span
      ><span
        >address , browser type, Internet service provider, device type, model,
        manufacturer, operating system, date and time, a&nbsp;</span
      ><span>unique identifier&nbsp;</span
      ><span
        >that allows us to identify your browser, mobile device, or account
        (including, but not limited to, a persistent device identifier for
        advertising), and other similar information. We may also work with
        third-party partners to employ technologies, including statistical
        modeling tools, that allow us to recognize and communicate with you
        across multiple devices. The purpose of this is to use device and
        software information to diagnose and fix technical issues, provide you
        with personalized advertising, personalize your experience, perform
        technical and technological analyses, and improve our products and
        services.</span
      >
    </p>
    <p>
      <span>Information about how you access and use our Services.&nbsp;</span
      ><span
        >&nbsp;This includes the website you came from and the website you go to
        next. We also track the pages you visit on the Platform, the links you
        click on the Platform, whether you open emails or click on links in
        emails we send, and other actions you take on the Platform. We may use
        this information to identify products and services that may be of
        interest to you, to improve our products and services, to measure the
        success of our advertising and marketing campaigns, and to send
        marketing communications.</span
      >
    </p>
    <p>
      <span>Analytical Information.&nbsp;</span>
      We may collect analytical data or use third-party analytics tools such as
      <span
        >Google Analytics to help us measure the volume of traffic, usage, and
        demographics of users of the Platform in order to improve our Services.
        You can learn more about&nbsp;</span
      ><span>Google &apos;s&nbsp;</span><span>practices&nbsp;</span
      ><span>at &nbsp;</span
      ><span>http://www.google.com/policies/privacy/partners&nbsp;</span
      ><span>.</span>
    </p>
    <p>
      <span>Geolocation Information.&nbsp;</span
      ><span
        >&nbsp;In some cases, you as a Platform user may provide us with your
        geolocation information directly (for example, when you provide a
        merchant with a delivery address for an item), and in other cases, your
        location may be monitored by GPS&nbsp;</span
      ><span>,&nbsp;</span
      ><span
        >Wi-Fi, or cell towers, or we may infer your location based on other
        information we collect. (For example, we may approximate your location
        based on your&nbsp;</span
      ><span>IP address&nbsp;</span
      ><span
        >.) We use this information to personalize content on our Services (for
        example, to show you stores near you), facilitate ordering and delivery,
        combat fraud, measure traffic, and perform technical and business
        analytics on the Services.</span
      >
    </p>
    <p>
      <span>Please note that we also use the Google&nbsp;</span
      ><span>Maps API&nbsp;</span
      ><span>to collect information about your location.&nbsp;</span
      ><span>Google uses&nbsp;</span
      ><span
        >various technologies to determine your location, including&nbsp;</span
      ><span>your IP&nbsp;</span><span>address ,&nbsp;</span
      ><span>GPS&nbsp;</span
      ><span>, and other sensors that may provide&nbsp;</span
      ><span>Google&nbsp;</span
      ><span
        >with information about nearby devices, Wi-Fi hotspots, and cell towers
        (see&nbsp;</span
      ><span>Google&apos;s Privacy Policy and&nbsp;</span
      ><span>Google&nbsp;</span><span>Maps Terms of Service &nbsp;</span
      ><span>to learn more).</span>
    </p>
    <p>
      <span>1/3. Data we collect from other sources:</span>
    </p>
    <p>
      <span
        >Information we receive from third-party sites that our Services connect
        to&nbsp;</span
      ><span
        >. We may also receive personal information about you from third parties
        and combine it with information we collect through our Platform. For
        example, we may receive information when you log in through a social
        network or third-party authentication service, such as&nbsp;</span
      ><span>Facebook&nbsp;</span><span>or&nbsp;</span><span>Google&nbsp;</span
      ><span
        >. These services authenticate your identity and provide you with the
        option to share certain personal information with us, which may include
        your name, email address, address book, contacts, or other information.
        Similarly, when you interact with us through a third-party social media
        site or service, such as when you like, follow, or share paid content on
        Facebook,&nbsp;</span
      ><span>Twitter&nbsp;</span><span>,</span> or
      <span
        >other sites, we may receive information that the social network allows
        to be shared with third parties. The data we receive from these
        third-party sites depends on the third party&rsquo;s policies and your
        privacy settings on the third-party site, and you should review those
        agreements and policies before using them.</span
      >
    </p>
    <p>
      <span>&nbsp;</span>
    </p>
    <p>
      <strong
        ><strong
          ><label
            ><span>2: Use and processing of personal data:&nbsp;</span></label
          ></strong
        ></strong
      >
    </p>
    <p>
      <span
        >In addition to the uses described above, we will use your personal data
        in accordance with applicable laws and regulations and this privacy
        policy, for several purposes, including the following:</span
      >
    </p>
    <p>
      <span
        >2/1. Providing, maintaining, improving and developing the Platform and
        Services:</span
      >
    </p>
    <p>
      <span>Including, for example:&nbsp;</span>
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >Enable you to create accounts or access and use the Platform and
        Services (for example, but not limited to: saving information so that
        you do not have to re-enter it during your visit or the next time you
        visit the Platform).</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >Fulfill your requests and process payments (for example, but not
        limited to: allowing you to complete and deliver a purchase from stores,
        or providing you with information you request)&nbsp;</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >Providing customized and personalized content and information to
        enhance and improve your experience on the Madfoo platform, and to
        provide you with features and improvements to the service.</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >Conducting technical, technological and marketing analyses, and
        discovering usage patterns on our platform and services.</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >Test and develop new features and functionality and improve the
        Platform and Services.</span
      >
    </p>
    <p>
      <span
        >2/2. Maintaining the integrity, stability and security of our platform
        and services: &nbsp;</span
      ><span
        >We may use personal information to maintain the integrity and security
        of our platform and services, including, but not limited to:</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span>Detect or prevent fraud, abuse, or other harmful activity.</span>
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span>Diagnose or repair technical problems.</span>
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >Conduct security investigations and risk assessments, and improve and
        enforce our preventive measures.</span
      >
    </p>
    <p>
      <span
        >2/3. Communicating with you to provide support and other important
        messages:&nbsp;</span
      ><span
        >&nbsp;We may use personal information to deliver important messages to
        you or to provide you with support. We may send you emails or
        service-related messages, for example (user account verification,
        changes or updates to platform features or services, technical and
        security notices). Please note that Madfoo is not responsible for
        messages that are delayed or not delivered.</span
      >
    </p>
    <p>
      <span
        >2/4. Providing, personalizing, measuring and improving our
        marketing:&nbsp;</span
      ><span
        >&nbsp;We may use personal information to provide, personalizing,
        measuring and improving our marketing, including, for example:</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >Administer rewards, surveys, contests, promotions, or other events
        sponsored or administered by Paid or its third party partners.</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >Informing you about products, programs, services and promotions that we
        think may be of interest to you, through emails, push notifications and
        other communications.</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >Allowing you to refer us to your friends so that we can send them a
        promotional offer through the Platform. If you wish to use this feature,
        you may be asked to provide us with, and we may use, your friend&rsquo;s
        name, email address, and other information you share with us. By using
        this functionality, you confirm that we have the right to use your
        friend&rsquo;s name and email address for this purpose. We will use this
        information to send an email inviting the friend to visit the Platform
        and for any other purposes disclosed at the time you participate in the
        Program.</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span
        >Determine the effectiveness of our services and promotions, and
        recognize and communicate with you across multiple devices.</span
      >
    </p>
    <p>
      <span
        >2/5. Compliance with legal and regulatory orders and
        procedures:&nbsp;</span
      ><span
        >&nbsp;We may use personal information in connection with legal and
        regulatory procedures and requirements, for example (we may need
        personal information for the purpose of implementing the obligations
        imposed on us as a company, such as reporting and others, or when we
        receive a judicial order or a government summons requesting disclosure
        of data, or there is an interest in our sole discretion that disclosure
        of personal data is necessary in accordance with what is required by the
        laws and instructions).</span
      >
    </p>
    <p>
      <span>2/6. Preserving our rights: &nbsp;</span
      ><span
        >When you access or use our platform or services, you are bound by these
        Terms and Conditions of Use and Privacy Policy, to ensure your
        compliance with them we may use your personal information to monitor,
        investigate, prevent or mitigate violations of our Terms of Use as well
        as to enforce our agreements with third parties and business
        partners.</span
      >
    </p>
    <p>
      <strong
        ><strong
          ><label
            ><span
              >3: Our sharing and disclosure of personal information:</span
            ></label
          ></strong
        ></strong
      >
    </p>
    <p>
      <span
        >Please note that we disclose your personal information to the following
        third parties:</span
      >
    </p>
    <p>
      <span>3/1. Paid Sellers:&nbsp;</span
      ><span
        >&nbsp;We may disclose your personal information to the sellers you
        purchase from, and third parties associated with those sellers (such as
        their point-of-sale providers or delivery personnel), as needed to
        fulfill your orders, including communicating with you about the status
        of your order or cancelled items, and if you make a purchase from a
        seller that offers loyalty or rewards programs through our Platform, we
        may disclose your personal information to the supplier as needed to
        facilitate your participation in the seller&rsquo;s loyalty or rewards
        programs, including tracking points and redemptions.</span
      >
    </p>
    <p>
      <span>3/2. Service Providers and Partners:&nbsp;</span
      ><span
        >&nbsp;We may disclose personal information to third parties who provide
        services to us or on our behalf, such as website hosting, data analysis,
        provision of IT infrastructure and services, customer service, email
        delivery services, fraud detection, security monitoring, advertising and
        marketing, and other similar services.</span
      >
    </p>
    <p>
      <span>3/3. Analytics Service Providers:&nbsp;</span
      ><span
        >&nbsp;We may share your personal information with analytics service
        providers to better understand the demographics of our users and
        visitors, and to customize and improve our Platform and Services.</span
      >
    </p>
    <p>
      <span
        >3/4. Advertising, Marketing and Promotional Service
        Providers:&nbsp;</span
      ><span
        >&nbsp;We may share personal information with third parties in
        connection with joint marketing activities, developing customized and
        targeted marketing campaigns, launching promotions and other similar
        advertising efforts. For example:&nbsp;</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span>Advertisers:&nbsp;</span
      ><span
        >&nbsp;We may disclose your Personal Information to advertising sponsors
        of promotions to provide joint promotions, activities or co-branded
        services, and this disclosure is necessary to fulfill your request. You
        should therefore review the rules and terms of each promotion in which
        you participate, as the promotion may contain additional important
        information about our use of your Personal Information, or about the
        advertiser&rsquo;s use to the extent that the rules or terms of those
        promotions regarding the processing of your Personal Information
        conflict with this Privacy Policy, and the rules of those promotions
        will apply.</span
      >
    </p>
    <p>
      <span class="light-text">&middot; &nbsp; &nbsp; &nbsp; &nbsp;</span
      ><span>Social Networks:</span> We may disclose personal information, such
      as your identifier, email address, or information automatically collected
      through tracking technologies, to social networks for the purpose of
      displaying personalized advertising and content on those social networks.
    </p>
    <p>
      <span>3/5. Vendors and Brands: &nbsp;</span
      ><span
        >We may disclose Personal Information to a select group of vendors and
        brands from whom you have previously purchased through the Platform, as
        well as their affiliates and third party service providers. These
        vendors, brands and their affiliates may use your information as
        described in their privacy policies, which may include sending you
        marketing messages and other promotional content.</span
      >
    </p>
    <p>
      <span>3/6. Public Services and Platforms:&nbsp;</span
      ><span
        >&nbsp;We provide certain features through the Services that may enable
        you to post information and materials, for example, ratings and reviews,
        uploading photos, and chat services; please note that any information
        you provide in connection with these Services may be available to
        visitors to the Site and the general public. Please do not disclose your
        personal information in these public or interactive areas of the
        Services, and we urge you to exercise discretion and caution when
        deciding to disclose any information or other materials in these public
        or interactive areas of the Services.</span
      >
    </p>
    <p>
      <span>3/7. Legal rights and obligations:&nbsp;</span
      ><span
        >&nbsp;We may disclose personal information to third parties, such as
        legal advisors, as required by law or anti-fraud rules, reduce credit
        risk, collect debts owed to us, or exercise or protect the rights,
        property, or personal safety of Madfoo, our visitors, or others.</span
      >
    </p>
    <p>
      <span>3/8: When you have your consent: &nbsp;</span
      ><span
        >We may disclose personal information about you to other third parties
        with your consent.</span
      >
    </p>
    <p>
      <strong
        ><strong
          ><label
            ><span>4: How we store and protect your data:</span></label
          ></strong
        ></strong
      >
    </p>
    <p>
      <span>Data Storage and Transfer: &nbsp;</span
      ><span
        >Your information collected through our Service is stored and processed
        in the Kingdom of Saudi Arabia. If you create an account on the Platform
        as a resident outside the Kingdom of Saudi Arabia, then by using the
        Platform or the Services, you agree to this Privacy Policy and consent
        to the collection, transfer, storage and processing of information to
        the Kingdom of Saudi Arabia.</span
      >
    </p>
    <p>
      <span>Data Security:&nbsp;</span
      ><span
        >&nbsp;We will endeavor to keep your information secure, and we care
        about the security of your information and use physical, administrative,
        and technological safeguards designed to maintain the integrity and
        security of all information collected through our Service. Please be
        aware that protecting your personal information is also your
        responsibility, and therefore we strongly recommend that you change your
        passwords periodically, use a combination of letters, numbers, and
        special characters, ensure that you use a secure browser, and take
        precautions when sharing your personal information online. If you know
        or have reason to believe that your account information has been lost,
        stolen, misappropriated, or otherwise compromised, or in the event of
        any actual or suspected unauthorized use, please contact us by following
        the instructions in the Contact Us section below.</span
      >
    </p>
    <p>
      <strong
        ><strong
          ><label
            ><span>5: Your rights over your personal data:</span></label
          ></strong
        ></strong
      >
    </p>
    <p>
      <span>Update your information:&nbsp;</span
      ><span
        >&nbsp;You can update your information by following the instructions on
        your Madfoo account page.</span
      >
    </p>
    <p>
      <span
        >Controlling access to your device information and push
        notifications:&nbsp;</span
      ><span
        >&nbsp;You can control access to your device information or opt out of
        push notifications through the Settings app on your device. For example,
        you can withdraw an app&rsquo;s permission to access your location data
        through your device settings, although we may continue to approximate
        your location based on your&nbsp;</span
      ><span>IP address&nbsp;</span><span>or through other means.</span>
    </p>
    <p>
      <span>Communication Settings:&nbsp;</span
      ><span
        >&nbsp;You may also opt out of receiving promotional email
        communications through the communication settings in a paid account, but
        you cannot opt out of service-related communications (e.g., account and
        identity verification, purchase-related communications, changes and
        updates to features of the Services, legal notices, technical and
        security notices).</span
      >
    </p>
    <p>
      <span>Deletion of your data:&nbsp;</span
      ><span
        >&nbsp;In all cases, you have the right to request that we delete your
        information that we hold at any time by sending your request to any of
        our addresses listed in this agreement. However, please note that we may
        not be able to fulfill such a request in some cases provided for by law
        or in the event of financial claims against you.</span
      >
    </p>
    <p>
      <strong
        ><strong
          ><label
            ><span>6: Duration of retention of personal data:</span></label
          ></strong
        ></strong
      >
    </p>
    <p>
      <span
        >The period for which we retain personal data is the period required to
        fulfil the purposes for which we collected the data, including purposes
        related to fulfilling any legal, regulatory, tax, financial and other
        requirements related to this matter. This period may be extended in the
        event of legal proceedings or the possibility of them. If there are no
        reasons or requirements to preserve it, we will then cancel it.</span
      >
    </p>
    <p>
      <strong
        ><strong
          ><label><span>7: How we dispose of your data:</span></label></strong
        ></strong
      >
    </p>
    <p>
      <span
        >If it is no longer necessary to store personal data, we will then
        delete your data from our databases.&nbsp;</span
      >
    </p>
    <p>
      <strong
        ><strong
          ><label><span>8: Children&rsquo;s Privacy:</span></label></strong
        ></strong
      >
    </p>
    <p>
      <span
        >Our Services and our Platform are not intended for use by anyone under
        the age of 18. We do not knowingly collect any personally identifiable
        information from children under 18. If we discover that a child under 18
        has provided us with personal information, we immediately delete this
        from our servers. If you are a parent or guardian and you are aware that
        your child has provided us with personal information, please contact us
        so that we can do necessary actions.</span
      >
    </p>
    <p>
      <strong
        ><strong
          ><label><span>9: Third party sites:</span></label></strong
        ></strong
      >
    </p>
    <p>
      <span
        >Our websites and services may contain links to third-party websites,
        plug-ins and applications. Except as we expressly post, endorse or
        reference this Privacy Policy, this Privacy Policy does not apply to
        those websites, and we are not responsible for the personal information
        practices of third-party websites, services or practices. To learn about
        the personal information practices of third parties, please review their
        privacy policies.</span
      >
    </p>
    <p>
      <strong
        ><strong
          ><label><span>10: Changes to this policy:</span></label></strong
        ></strong
      >
    </p>
    <p>
      <span
        >Any changes we make to our Privacy Policy in the future will be posted
        on this page, and when we make changes to this Privacy Policy, we will
        change the &ldquo;effective&rdquo; date at the beginning of this Privacy
        Policy. If we make material changes to this Privacy Policy, we will
        notify you by email to your registered email address, by prominent
        posting on the Platform, or through other appropriate communication
        channels. All changes are effective from the date of posting unless
        otherwise stated. If you do not cancel your account before the effective
        date of the updated Privacy Policy, your continued access to or use of
        the Platform or Services will be subject to the updated Privacy
        Policy.</span
      >
    </p>
    <p>
      <strong
        ><strong
          ><label><span>11: Contact us:&nbsp;</span></label></strong
        ></strong
      >
    </p>
    <p>
      <span
        >If you have any questions or suggestions about our privacy policy,
        please feel free to contact us via email &nbsp;</span
      ><span>at contact@madfu.com.sa</span><span>&nbsp;</span>
    </p>
    <p>
      <span>&nbsp;</span>
    </p>
    <p>
      <span
        >*Please note that email communications are not always secure;
        therefore, please do not include credit card information and/or other
        sensitive personal information in your email correspondence with
        us.</span
      >
    </p>
  </div>
</div>
