import { Component, Input, OnInit } from '@angular/core';
import { ChangeLangService } from 'src/app/services/change-lang/change-lang.service';
import { FooterService } from './services/footer.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationLanguages } from 'src/app/enums/lang';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() pageTitle: string = '';

  ApplicationLanguages = ApplicationLanguages;
  currentLang = localStorage.getItem('lang') || ApplicationLanguages.Ar;
  footerData?: {
    email: string;
    phone: string;
    whatsapp: string;
    addressAr: string;
    addressEn: string;
    copyRightTextAr: string;
    copyRightTextEn: string;
  };

  constructor(
    private changelang: ChangeLangService,
    private footerServices: FooterService,
    protected translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getFooterMainData();
  }

  switchLang(lang: any) {
    this.changelang.changeLanguage(lang.target.value);
  }

  getFooterMainData() {
    this.footerServices.getFooterData().subscribe((res: any) => {
      this.footerData = res;
    });
  }
}
