<div class="islamic-cover">
  <div class="container">
    <div class="islamic-cover-widget d-flex">
      <h1>{{ "sharia-committee" | translate }}</h1>
      <img
        loading="lazy"
        src="/assets/images/islamic-committee-heading.svg"
        alt="islamic committee"
      />
    </div>

    <div class="islamic-container">
      <h2>
        {{
          "Pay-in-cash-and-pay-later-services-that-are-compatible-with-Islamic-law"
            | translate
        }}
      </h2>
      <p>
        {{ "sharia-committee-main-description" | translate }}
      </p>

      <ng-container *ngFor="let item of shariaData">
        <h3>
          {{
            currentLang == ApplicationLanguages.Ar ? item.titleAr : item.titleEn
          }}
        </h3>
        <p>
          {{
            currentLang == ApplicationLanguages.Ar ? item.descAr : item.descEn
          }}
        </p>
      </ng-container>
    </div>
  </div>
</div>
