<div class="email-confirmation-page">
  <img
    loading="lazy"
    src="/assets/images/madfu-logo.svg"
    alt="Logo"
    class="logo"
  />

  <div class="data-container">
    <img loading="lazy" [src]="image" alt="Email Confirmation" />
    <p class="title">{{ title | translate }}</p>
    <p class="description" [class.rejected]="!success">
      {{ message | translate }}
    </p>
  </div>
</div>
