<div *ngIf="pageTitle !== 'email-confirmation'" class="contact-us-widget">
  <div class="email">
    <a href="mailto:{{ footerData?.email }}">
      <img loading="lazy" src="assets/images/email.svg" alt="Email Icon" />
      <p>{{ footerData?.email }}</p>
    </a>
  </div>
  <div class="divider"></div>
  <div class="phone">
    <a href="tel:{{ footerData?.phone }}">
      <img loading="lazy" src="assets/images/phone.svg" alt="Phone" />
      <p>{{ footerData?.phone }}</p>
    </a>
  </div>
  <div class="divider"></div>
  <div class="phone">
    <a href="https://wa.me/{{ footerData?.whatsapp }}">
      <img loading="lazy" src="assets/images/whatsapp.svg" alt="WhatsApp" />
      <p>{{ footerData?.whatsapp }}</p>
    </a>
  </div>
</div>

<footer>
  <div class="">
    <div class="d-flex footer-widget">
      <div class="item logo-widget">
        <a [routerLink]="['/', translate.currentLang, 'home']" class="logo">
          <img loading="lazy" src="assets/images/footer-logo.svg" alt="Madfu" />
        </a>
        <p class="address">
          {{
            currentLang == ApplicationLanguages.Ar
              ? footerData?.addressAr
              : footerData?.addressEn
          }}
        </p>
        <p class="phone">
          {{ "contact-us-phone" | translate }}
          <span class="phone-number"> <span>+966 59 473 2871</span> </span>
        </p>
        <ul>
          <li>
            <a target="_blank" href="https://www.instagram.com/enjoy_madfu/">
              <img
                loading="lazy"
                src="assets/images/instagram.svg"
                alt="instagram"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="https://x.com/enjoy_madfu">
              <img
                loading="lazy"
                src="assets/images/twitter.svg"
                alt="twitter"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/madfu-limited"
            >
              <img
                loading="lazy"
                src="assets/images/linkedin.svg"
                alt="linkedin"
              />
            </a>
          </li>
        </ul>
      </div>

      <ul class="item listed-items">
        <li>{{ "get-to-know-us" | translate }}</li>

        <li>
          <a [routerLink]="['/', translate.currentLang, 'blogs']">
            {{ "our-news" | translate }}
          </a>
        </li>
        <!--        <li><a [routerLink]="['/', translate.currentLang, 'islamic-committee']" >{{'sharia-committee' | translate}}</a></li>-->
        <li>
          <a [routerLink]="['/', translate.currentLang, 'privacy']">
            {{ "privacy-policy" | translate }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/', translate.currentLang, 'terms-and-conditions']"
          >
            {{ "terms-and-conditions" | translate }}
          </a>
        </li>
      </ul>

      <ul class="item listed-items">
        <li>{{ "business" | translate }}</li>
        <li>
          <a [routerLink]="['/', translate.currentLang, 'be-partner']">
            {{ "join-our-partners" | translate }}
          </a>
        </li>
        <li>
          <a [routerLink]="['/', translate.currentLang, 'home']">
            {{ "Financial-Statements" | translate }}
          </a>
        </li>
      </ul>

      <ul class="item listed-items">
        <li>{{ "your-voice-is-heard" | translate }}</li>
        <li>
          <a [routerLink]="['/', translate.currentLang, 'faq']">
            {{ "help-center" | translate }}
          </a>
        </li>
        <li>
          <a [routerLink]="['/', translate.currentLang, 'faq']">
            {{ "FAQ" | translate }}
          </a>
        </li>
        <li>
          <a href="assets/Consumer_Protection.pdf" target="_blank">{{
            "Consumer-Protection" | translate
          }}</a>
        </li>
        <li>
          <a [routerLink]="['/', translate.currentLang, 'complaints']">
            {{ "COMPLAINTS_CENTER" | translate }}
          </a>
        </li>
      </ul>

      <div class="item get-app-widget">
        <p>{{ "download-the-app-now" | translate }}</p>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/madfu-%D9%85%D8%AF%D9%81%D9%88%D8%B9/id1658723268"
            >
              <img
                loading="lazy"
                src="assets/images/app-store.svg"
                alt="app store"
              />
            </a>
          </li>
          <li>
            <a target="_blank" href="https://app.adjust.com/1fz1h1en">
              <img
                loading="lazy"
                src="assets/images/play-store.svg"
                alt="play store"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="d-flex footer-copyright d-flex">
      <p class="footer-copyright-content-1">
        {{ "all-rights-reserved" | translate }}
      </p>

      <div class="footer-copyright-content-2">
        <p>
          {{
            currentLang == ApplicationLanguages.Ar
              ? footerData?.copyRightTextAr
              : footerData?.copyRightTextEn
          }}
        </p>
      </div>

      <div class="lang-widget">
        <select
          name="langs"
          id="langs"
          (change)="switchLang($event)"
          [(ngModel)]="currentLang"
        >
          <option [value]="ApplicationLanguages.Ar">Arabic</option>
          <option [value]="ApplicationLanguages.En">English</option>
        </select>
        <img loading="lazy" src="assets/images/lang.svg" alt="world" />
      </div>
    </div>
  </div>
</footer>
