import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationLanguages } from 'src/app/enums/lang';

@Injectable({
  providedIn: 'root',
})
export class ChangeLangService {
  ApplicationLanguages = ApplicationLanguages;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private router: Router
  ) {}

  setInitialAppLanguage(lang: string) {
    let htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    localStorage.setItem('lang', lang);
    htmlTag.dir = lang === ApplicationLanguages.Ar ? 'rtl' : 'ltr';
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    const body = document.querySelector('body')!;
    body.className =
      localStorage.getItem('lang') === ApplicationLanguages.Ar ? 'rtl' : 'ltr';
  }

  changeLanguage(lang: any, base?: string) {
    this.setInitialAppLanguage(lang);

    const urlSegments = this.router.url.split('/');
    urlSegments[1] = lang;

    window.location.href = urlSegments.join('/');
  }
}
