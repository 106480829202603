<div class="modal" (click)="onBackdropClick($event)">
  <div class="modal-content" (click)="$event.stopPropagation()">
    <img
      loading="lazy"
      class="customer-service"
      src="assets/images/partner/customer-service.png"
      alt="customer service"
    />

    <div class="content">
      <p class="title">{{ "have-issue-call-us" | translate }}</p>

      <p class="subtitle">{{ "do-you-need-support-team" | translate }}</p>
    </div>

    <div class="contact-info">
      <div class="item">
        <a href="https://wa.me/{{ footerData?.whatsapp }}">
          <img loading="lazy" src="assets/images/whatsapp.svg" alt="WhatsApp" />
          <p>{{ footerData?.whatsapp }}</p>
        </a>
      </div>

      <div class="divider"></div>
      <div class="item">
        <a href="tel:{{ footerData?.phone }}">
          <img loading="lazy" src="assets/images/phone.svg" alt="Phone" />
          <p>{{ footerData?.phone }}</p>
        </a>
      </div>
      <div class="divider"></div>

      <div class="item">
        <a href="mailto:{{ footerData?.email }}">
          <img loading="lazy" src="assets/images/email.svg" alt="Email Icon" />
          <p>{{ footerData?.email }}</p>
        </a>
      </div>
    </div>
  </div>
</div>
