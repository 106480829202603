<div [formGroup]="formGroup">
  <input
    type="file"
    class="file-input"
    accept=".pdf"
    (change)="onFileSelected($event)"
    (click)="removeFile()"
    #filePickerInput
  />
</div>

<div class="file-upload-container">
  <div
    class="file-upload"
    [class.isFilled]="file"
    [class.invalid]="
      formGroup.controls[fieldControl].invalid &&
      !formGroup.controls[fieldControl].pristine
    "
  >
    <button
      type="button"
      color="primary"
      class="upload-btn"
      (click)="filePickerInput.click()"
    >
      <div class="data">
        <img
          loading="lazy"
          [src]="'assets/images/partner/' + fieldControl + '.svg'"
          [alt]="fieldControl"
        />
        <span>{{ fieldControl | translate }}</span>
      </div>

      <button
        (click)="file ? onRemoveFile($event) : {}"
        class="upload-remove-button"
        type="button"
      >
        <img
          loading="lazy"
          [src]="
            file
              ? 'assets/images/partner/close.svg'
              : 'assets/images/partner/send-square.svg'
          "
          [alt]="file ? 'close' : 'send'"
        />
      </button>
    </button>
  </div>

  <div
    *ngIf="
      formGroup.controls[fieldControl].invalid &&
      formGroup.controls[fieldControl].touched
    "
    class="error"
  >
    {{ "you-must-upload-field" | translate }}
  </div>
</div>
