import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FooterService } from 'src/app/layout/footer/services/footer.service';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
})
export class ContactSupportComponent implements OnInit {
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  footerData?: {
    email: string;
    phone: string;
    whatsapp: string;
    addressAr: string;
    addressEn: string;
    copyRightTextAr: string;
    copyRightTextEn: string;
  };

  constructor(private footerServices: FooterService) {}

  ngOnInit(): void {
    this.getFooterMainData();
  }

  getFooterMainData() {
    this.footerServices.getFooterData().subscribe((res: any) => {
      this.footerData = res;
    });
  }

  onBackdropClick(event: MouseEvent) {
    if ((event.target as HTMLElement).classList.contains('modal')) {
      this.close();
    }
  }

  close() {
    this.closeModal.emit();
    document.body.classList.remove('no-scroll');
  }
}
