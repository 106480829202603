<div class="be-partner-cover">
  <div class="container">
    <div class="be-partner-cover-widget d-flex">
      <div class="be-partner-data">
        <img
          loading="lazy"
          class="sparkles"
          src="assets/images/partner/sparkles.png"
          alt="sparkles"
        />

        <h1>{{ "we-introduce-you-and-increase-your-sales" | translate }}</h1>
        <p>
          {{ "we-introduce-you-and-increase-your-sales-desc" | translate }}
        </p>
      </div>

      <div class="be-partner-form">
        <app-partner-form (openModal)="openModal()"></app-partner-form>
      </div>
    </div>

    <img
      loading="lazy"
      class="partners-header-intersection"
      src="/assets/images/partner/parners-header-intersection.png"
      alt="partners header intersection"
    />
  </div>
</div>

<div class="growth-together">
  <div class="container">
    <div class="growth-together-widget">
      <h2>{{ "we-grow-together" | translate }}</h2>
      <p>
        {{
          "madfu-Your-best-financial-partner-for-your-store-payments"
            | translate
        }}
      </p>
      <owl-carousel-o [options]="customOptionsSlider">
        <ng-template carouselSlide *ngFor="let item of growthData">
          <div class="growth-together-widget-item">
            <img
              loading="lazy"
              [src]="'/assets/images/' + item.image"
              [alt]="
                currentLang == ApplicationLanguages.Ar
                  ? item.titleAr
                  : item.titleEn
              "
            />
            <div>
              <h4>
                {{
                  currentLang == ApplicationLanguages.Ar
                    ? item.titleAr
                    : item.titleEn
                }}
              </h4>
              <p>
                {{
                  currentLang == ApplicationLanguages.Ar
                    ? item.descAr
                    : item.descEn
                }}
              </p>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</div>

<div class="our-main-partners-be-partner-page d-flex">
  <div class="container">
    <div class="our-main-partners-container d-flex">
      <div class="main-tabs">
        <h2 class="title">{{ "our-partners" | translate }}</h2>
        <p class="sub-title">
          {{ "our-partners-desc" | translate }}
        </p>
        <div class="tab">
          <button
            class="tablinks"
            (click)="openItem($event, 'store')"
            id="defaultOpen"
          >
            {{ "store" | translate }}
          </button>
          <button class="tablinks" (click)="openItem($event, 'online')">
            {{ "online" | translate }}
          </button>
        </div>
        <ul>
          <li>{{ "make-it-easier" | translate }}</li>
          <li>{{ "reach-a-larger" | translate }}</li>
          <li>
            {{ "increase-your-average" | translate }}
          </li>
        </ul>
      </div>
      <div class="tabs-content-widgets">
        <div id="store" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of storePartners | slice : 0 : 4">
              <p>
                {{
                  currentLang == ApplicationLanguages.Ar
                    ? store.titleAr
                    : store.titleEn
                }}
              </p>
              <img
                loading="lazy"
                [src]="'/assets/images/store/' + store.image"
                [alt]="
                  currentLang == ApplicationLanguages.Ar
                    ? store.titleAr
                    : store.titleEn
                "
              />
              <a target="_blank" [href]="discoverLink">{{
                "explore" | translate
              }}</a>
            </li>
          </ul>
        </div>

        <div id="online" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of onlinePartners | slice : 0 : 4">
              <p>
                {{
                  currentLang == ApplicationLanguages.Ar
                    ? store.titleAr
                    : store.titleEn
                }}
              </p>
              <img
                loading="lazy"
                [src]="'/assets/images/online/' + store.image"
                [alt]="
                  currentLang == ApplicationLanguages.Ar
                    ? store.titleAr
                    : store.titleEn
                "
              />
              <a target="_blank" [href]="discoverLink">{{
                "explore" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
<app-three-steps-partner-form></app-three-steps-partner-form>
-->

<app-contact-support
  *ngIf="showModal"
  (closeModal)="closeModal()"
></app-contact-support>
