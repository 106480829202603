import { Component, OnInit } from '@angular/core';
import { CategoriesService } from '../home/service/categories.service';
import { partner } from 'src/app/interfaces/category';
import { ApplicationLanguages } from 'src/app/enums/lang';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit {
  discoverLink = 'https://app.adjust.com/1fz1h1en';
  storePartners: partner[] = [];
  onlinePartners: partner[] = [];
  growthData: any;
  ApplicationLanguages = ApplicationLanguages;
  currentLang = localStorage.getItem('lang');
  showModal = false;

  customOptionsSlider: any = {
    loop: true,
    margin: 20,
    nav: true,
    autoplay: false,
    rtl: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  constructor(private readonly categoriesService: CategoriesService) {}

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById('defaultOpen')!.click();
    }, 500);

    this.getStorePartnersData();
    this.getOnlinePartnersData();
    this.getGrowthTogetherData();
  }

  getStorePartnersData() {
    this.categoriesService.getStorePartners().subscribe((response: any) => {
      this.storePartners = response;
    });
  }
  getOnlinePartnersData() {
    this.categoriesService.getOnlinePartners().subscribe((response: any) => {
      this.onlinePartners = response;
    });
  }

  getGrowthTogetherData() {
    this.categoriesService.getGrowthData().subscribe((response: any) => {
      this.growthData = response;
    });
  }

  openItem(evt: MouseEvent, itemName: string): void {
    // Declare all variables
    let i: number;
    let tabcontent: HTMLCollectionOf<Element>;
    let tablinks: HTMLCollectionOf<Element>;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      (tabcontent[i] as HTMLElement).style.display = 'none';
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(itemName)!.style.display = 'block';
    (evt.currentTarget as HTMLElement).className += ' active';
  }

  scrollToForm() {
    document.getElementById('join-us')?.scrollIntoView({
      behavior: 'smooth',
    });
  }

  openModal() {
    this.showModal = true;
    document.body.classList.add('no-scroll');
  }

  closeModal() {
    this.showModal = false;
  }
}
