<div class="cover">
  <div class="container d-flex">
    <div class="cover-images">
      <div class="users">
        <img loading="lazy" src="/assets/images/users-img.svg" alt="users" />
        <p>
          <label
            ><span class="plus">+</span>14
            <span class="thousand">{{ "million" | translate }}</span>
          </label>
          <span class="user">{{
            "used-in-the-Kingdom-through-deferred-payment" | translate
          }}</span>
        </p>
      </div>
      <img
        loading="lazy"
        class="main-img"
        src="/assets/images/home-pg.svg"
        alt="home bg"
      />
    </div>

    <div class="cover-data">
      <h1>{{ "Pay-with-madfu-and-pay-later" | translate }}</h1>
      <div class="buttons">
        <button
          [routerLink]="['/', translate.currentLang, 'be-partner']"
          class="be-partner-button"
        >
          <img
            loading="lazy"
            src="/assets/images/send-square.svg"
            alt="send square"
          />
          {{ "register-as-a-partner" | translate }}
        </button>

        <div>
          <a target="_blank" href="https://app.adjust.com/1fz1h1en"
            ><img
              loading="lazy"
              src="/assets/images/app-store-cover.svg"
              alt="app store"
          /></a>
          <a target="_blank" href="https://app.adjust.com/1fz1h1en"
            ><img
              loading="lazy"
              src="/assets/images/google-cover.svg"
              alt="google"
          /></a>
          <p>{{ "download-the-app-now" | translate }}</p>
        </div>
      </div>

      <ul class="features">
        <li>
          <div>
            <img
              loading="lazy"
              src="/assets/images/cover-1.svg"
              alt="payment options"
            />
            <p class="option-title">{{ "payment-options" | translate }}</p>
          </div>
          <span>{{ "payment-options-desc" | translate }}</span>
        </li>
        <li>
          <div>
            <img
              loading="lazy"
              src="/assets/images/cover-2.svg"
              alt="sharia compliant"
            />
            <p class="option-title">{{ "sharia-compliant" | translate }}</p>
          </div>
          <span>{{ "sharia-compliant-desc" | translate }}</span>
        </li>
        <li>
          <div>
            <img
              loading="lazy"
              src="/assets/images/cover-3.svg"
              alt="register"
            />
            <p class="option-title">{{ "register-and-explore" | translate }}</p>
          </div>
          <span>{{ "register-and-explore-desc" | translate }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="options">
  <div class="container">
    <div class="options-wrapper d-flex">
      <div class="choose-option">
        <h3>{{ "choose-the-category-that-suits-you" | translate }}</h3>

        <div class="slider">
          <owl-carousel-o [options]="customOptionsSliderOne">
            <ng-template
              carouselSlide
              *ngFor="let category of sliderCategories"
            >
              <div class="slider-item">
                <img
                  loading="lazy"
                  [src]="'/assets/images/' + category.image"
                  [alt]="category.title"
                />
                <p>{{ category.title }}</p>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>

        <img
          loading="lazy"
          class="logo"
          [src]="
            currentLang === ApplicationLanguages.Ar
              ? '/assets/images/logo-cutout.svg'
              : '/assets/images/logo-cutout-en.svg'
          "
          alt="madfu"
        />
      </div>

      <div class="all-options">
        <div class="all-options-wrapper d-flex">
          <div class="option" *ngFor="let category of categories">
            <img
              loading="lazy"
              class="image"
              [src]="'/assets/images/' + category.image"
              [alt]="category.title"
            />
            <p class="title">{{ category.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="islamic-law">
  <div class="container">
    <div class="islamic-law-widget d-flex">
      <img
        loading="lazy"
        src="/assets/images/islamic-law-img.svg"
        alt="islamic law"
      />

      <div class="islamic-law-data">
        <h3>{{ "compatible-with-islamic-sharia" | translate }}</h3>
        <p>
          {{ "compatible-with-islamic-sharia-desc" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="payment-way">
  <div class="container">
    <div class="payment-way-widget d-flex">
      <h2 class="title">{{ "pay-your-way" | translate }}</h2>
      <p class="sub-title">
        {{ "pay-your-way-desc" | translate }}
      </p>

      <div class="data d-flex">
        <div class="data-widget installments">
          <span>{{ "divide-into-several-batches" | translate }}</span>
          <h4>{{ "split-up-to-6-payments" | translate }}</h4>
          <label>{{ "hidden-fees" | translate }}</label>
        </div>

        <div class="payment-options">
          <div class="payment-options-widget">
            <div class="option installment-payment">
              <div class="option-data">
                <div class="details">
                  <p class="title">{{ "installment-payments" | translate }}</p>
                  <p class="subtitle">
                    {{ "divide-into-equal-amounts" | translate }}
                  </p>
                </div>

                <button (click)="togglePaymentExpanded(1)">
                  <img
                    loading="lazy"
                    class="icon"
                    [ngClass]="{ rotate: paymentExpandedId === 1 }"
                    src="/assets/images/down-arrow.svg"
                    alt="down arrow"
                  />
                </button>
              </div>

              <div class="installment-options" *ngIf="paymentExpandedId === 1">
                <div class="installment-option">
                  <p class="installments">
                    <span></span>{{ "3-payments" | translate }}
                  </p>
                  <p class="pay-now-amount">
                    {{ "Pay-now-50-riyals" | translate }}
                  </p>
                </div>

                <div class="installment-option">
                  <p class="installments">
                    <span></span>{{ "4-payments" | translate }}
                  </p>
                  <p class="pay-now-amount">
                    {{ "pay-now-37-riyals" | translate }}
                  </p>
                </div>

                <div class="installment-option">
                  <p class="installments">
                    <span></span>{{ "5-payments" | translate }}
                  </p>
                  <p class="pay-now-amount">
                    {{ "pay-now-30-riyals" | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="option salary-day">
              <div class="option-data">
                <div class="details">
                  <p class="title">{{ "salary-day" | translate }}</p>
                  <p class="subtitle">
                    {{ "pay-a-down-payment -nd-the-rest-later" | translate }}
                  </p>
                </div>

                <button (click)="togglePaymentExpanded(2)">
                  <img
                    loading="lazy"
                    class="icon"
                    [ngClass]="{ rotate: paymentExpandedId === 2 }"
                    src="/assets/images/down-arrow.svg"
                    alt="down arrow"
                  />
                </button>
              </div>

              <div class="salary-day-payment" *ngIf="paymentExpandedId === 2">
                <img
                  loading="lazy"
                  src="/assets/images/payment-counter.svg"
                  alt="payment counter"
                />

                <div class="available-amount-widget">
                  <p class="available-amount">
                    {{ "available-value" | translate }}
                  </p>
                  <p class="amount">
                    <span>80</span> {{ "riyal" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="data-widget salary">
          <span>{{ "salary-day-offers" | translate }}</span>
          <h4>{{ "salary-day-offers-desc" | translate }}</h4>
          <label>{{ "smooth-and-hassle-free" | translate }}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="offers">
  <div class="container">
    <div class="offers-widget">
      <div class="offers-data">
        <h3>{{ "exclusive-discounts-and-rewards" | translate }}</h3>
        <p>
          {{ "exclusive-discounts-and-rewards-desc" | translate }}
        </p>
      </div>

      <div class="offers-partners d-flex">
        <img
          loading="lazy"
          class="img"
          src="/assets/images/offers-img.svg"
          alt="our partners offers"
        />
        <div class="partners-widget d-flex">
          <h3>{{ "our-partners-offers" | translate }}</h3>
          <p class="weekly-exclusive">
            {{ "you-can-find-it-through-our-accounts" | translate }}
          </p>
          <p class="trusted-from">{{ "trusted-by" | translate }}</p>

          <div class="trusted-from-widget">
            <img
              loading="lazy"
              src="/assets/images/youtube.svg"
              alt="facebook"
            />
            <img
              loading="lazy"
              src="/assets/images/face-book.svg"
              alt="facebook"
            />
            <img loading="lazy" src="/assets/images/google.svg" alt="google" />
          </div>

          <a target="_blank" href="https://www.instagram.com/enjoy_madfu/">
            <img
              loading="lazy"
              src="/assets/images/clipboard-export.svg"
              [alt]="'discover' | translate"
            />
            {{ "discover" | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="our-main-partners">
  <div class="container">
    <div class="our-main-partners-container">
      <h2 class="title">{{ "our-partners" | translate }}</h2>
      <p class="sub-title">
        {{ "our-partners-desc" | translate }}
      </p>
      <div class="main-tabs">
        <div class="tab">
          <button
            class="tablinks"
            (click)="openItem($event, 'store')"
            id="defaultOpen"
          >
            {{ "store" | translate }}
          </button>
          <button class="tablinks" (click)="openItem($event, 'online')">
            {{ "online" | translate }}
          </button>
        </div>

        <div id="store" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of storePartners">
              <p>{{ currentLang == ApplicationLanguages.Ar ? store.titleAr : store.titleEn }}</p>
              <img
                loading="lazy"
                [src]="'/assets/images/store/' + store.image"
                [alt]="
                  currentLang == ApplicationLanguages.Ar
                    ? store.titleAr
                    : store.titleEn
                "
              />
              <a target="_blank" [href]="discoverLink">{{
                "explore" | translate
              }}</a>
            </li>
          </ul>
        </div>

        <div id="online" class="tabcontent">
          <ul class="partners-items">
            <li *ngFor="let store of onlinePartners">
              <p>{{ currentLang == ApplicationLanguages.Ar ? store.titleAr : store.titleEn }}</p>
              <img
                loading="lazy"
                [src]="'/assets/images/online/' + store.image"
                [alt]="
                  currentLang == ApplicationLanguages.Ar
                    ? store.titleAr
                    : store.titleEn
                "
              />
              <a target="_blank" [href]="discoverLink">{{
                "explore" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="download-app-now">
  <div class="container">
    <div class="download-app-now-widget d-flex">
      <div class="download-app-now-data">
        <h3>{{ "download-the-app-now" | translate }}</h3>
        <p>
          {{ "download-the-app-now-desc" | translate }}
        </p>
        <ul class="download">
          <li>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.sa.app.madfuser"
              class="d-flex"
            >
              <img
                loading="lazy"
                src="/assets/images/download-widget-playstore.svg"
                alt="play store"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/madfu-%D9%85%D8%AF%D9%81%D9%88%D8%B9/id1658723268"
              class="d-flex"
            >
              <img
                loading="lazy"
                src="/assets/images/download-widget-appstore.svg"
                alt="app store"
              />
            </a>
          </li>
        </ul>
        <ul class="statistics">
          <li>
            <p class="title-top">
              {{ "Upto" | translate }}
            </p>
            <p class="number">4%</p>
            <p class="title">{{ "cashback" | translate }}</p>
          </li>
          <li>
            <p class="title-top">
              {{ "Upto" | translate }}
            </p>
            <p class="number">6</p>
            <p class="title">
              {{ "interest-free-payments" | translate }}
            </p>
          </li>
        </ul>
      </div>
      <img
        loading="lazy"
        class="widget-img"
        src="/assets/images/download-app-now-widget-img.svg"
        alt="download app now"
      />
    </div>
  </div>
</div>

<div class="know-you">
  <div class="container">
    <div class="know-you-widget d-flex">
      <div class="know-you-data">
        <h2 class="introduce-you-and-increase-your-sales">
          {{ "we-introduce-you-and-increase-your-sales" | translate }}
        </h2>
        <ul>
          <li>{{ "make-it-easier" | translate }}</li>
          <li>{{ "reach-a-larger" | translate }}</li>
          <li>{{ "increase-your-average" | translate }}</li>
        </ul>

        <button
          [routerLink]="['/', translate.currentLang, 'be-partner']"
          class="be-partner-button"
        >
          <img
            loading="lazy"
            src="/assets/images/send-square.svg"
            alt="send square"
          />
          {{ "register-as-a-partner" | translate }}
        </button>
      </div>

      <div class="madfu-partners">
        <div class="text">
          <p>{{ "madfu-partners" | translate }}</p>
          <h3>{{ "add-madfu-to-your-store-payment-methods" | translate }}</h3>
          <img
            loading="lazy"
            src="/assets/images/small-car.svg"
            alt="small car"
          />
        </div>

        <ul>
          <li>
            <img
              loading="lazy"
              src="/assets/images/store/store-FU0AmyoWAAMTnt4.png"
              alt="Alodib"
            />
          </li>
          <li>
            <img
              loading="lazy"
              src="/assets/images/store/store-qasr.png"
              alt="store qasr"
            />
          </li>
          <li>
            <img
              loading="lazy"
              src="/assets/images/store/store-shaker.png"
              alt="store shaker"
            />
          </li>
          <li>
            <img
              loading="lazy"
              src="/assets/images/store/store-elsaif.png"
              alt="store elsaif"
            />
          </li>
          <li>
            <img
              loading="lazy"
              src="/assets/images/online/online-almanea.png"
              alt="online almanea"
            />
          </li>
          <li>
            <img
              loading="lazy"
              src="/assets/images/online/online-duvet.png"
              alt="online duvet"
            />
          </li>
          <li>
            <img
              loading="lazy"
              src="/assets/images/online/online-sayara-tech.png"
              alt="sayara tech"
            />
          </li>
          <li>
            <img
              loading="lazy"
              src="/assets/images/online/online-clara.png"
              alt="clara"
            />
          </li>
          <li>
            <img
              loading="lazy"
              src="/assets/images/online/online-car-station.png"
              alt="car station"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
