import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class TokenInitService {
  constructor(private http: HttpClient) {}

  tokenInit() {
    return this.http.post(environment.BackEndURL + '/api/user/token/init', {
      uuid: uuid().replace(/-/g, ''),
      systemInfo: window.navigator.userAgent,
    });
  }
}
