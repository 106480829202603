import { Component, OnInit } from '@angular/core';
import { FaqService } from './services/faq.service';
import { Faq, FaqList } from 'src/app/interfaces/faq';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApplicationLanguages } from 'src/app/enums/lang';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  currentLang = localStorage.getItem('lang') || ApplicationLanguages.En;
  faqList: any = null;
  ApplicationLanguages = ApplicationLanguages;

  constructor(
    private faqService: FaqService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getFaqList();
  }

  getFaqList() {
    this.faqService.getCategories().subscribe((data: FaqList) => {
      const madfuPartners = data.madfuPartners.map((item) => ({
        ...this.sanitizeDescription(item),
      }));

      this.faqList = { ...data, madfuPartners };
    });
  }

  sanitizeDescription(item: Faq) {
    if (!item.link) return item;

    const newItem = { ...item };

    const replacedArDescription = newItem.descriptionAr
      .replace(
        'start_link',
        `<a class='description-link' href="${item.link}" target=_blank>`
      )
      .replace('end_link', '</a>');

    const replacedEnDescription = newItem.descriptionEn
      .replace(
        'start_link',
        `<a class='description-link' href="${item.link}" target=_blank>`
      )
      .replace('end_link', '</a>');

    return {
      newItem,
      descriptionAr: this.sanitizer.bypassSecurityTrustHtml(
        replacedArDescription
      ),
      descriptionEn: this.sanitizer.bypassSecurityTrustHtml(
        replacedEnDescription
      ),
    };
  }
}
